import { fade } from '@material-ui/core/styles/colorManipulator';

const colors = {
  // Black and white colors
  white: '#FFFFFF',
  black: '#000000',

  // Primary colors and shades
  primaryColor: '#02BBD3',
  darkerPrimaryColor: '#00A6BC',
  darkPrimaryColor: '#007EA7',

  // Secondary colors
  secondaryColor: '#324D59',

  // Notification colors
  successColor: '#7AC7AF',
  errorColor: '#EB5757',

  // Text colors
  primaryText: '#0B2027',
  secondaryText: '#B6BCBE',
  additionalText: '#53565A',

  // Background colors
  defaultBackground: '#F2F2F2',
  paperBackground: '#FFFFFF',

  // Header colors
  headerColor: '#0B2027',
  headerBackgroundColor: '#FFFFFF',

  // Footer colors
  footerColor: '#FFFFFF',
  footerBackgroundColor: '#0B2027',

  // Border colors
  borderColor: '#C7CEE2',

  // Grey colors
  grey300: '#F9F9F9',

  // Disabled colors
  disabledBackground: '#F9F9F9',

  // Table colors
  toolbarText: '#4D5859',
  headText: '#97999B',

  // Backdrop colors
  backdropBackground: fade('#FFF', 0.92)
};

export default colors;
