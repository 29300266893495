import { useDispatch } from 'react-redux';
import { useDeepCompareCallback } from 'use-deep-compare';

import { filterAndSearchExperienceForOwnerAsync } from '@ducks/roar';

export default function useFilterAndSearchExperienceForOwner(
  initialParams = {}
) {
  const dispatch = useDispatch();

  return useDeepCompareCallback(
    (params = {}) => {
      dispatch(
        filterAndSearchExperienceForOwnerAsync({ ...initialParams, ...params })
      );
    },
    [dispatch, initialParams]
  );
}
