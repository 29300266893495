import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';

// const baseEndpoint = new Endpoint('addon');

export const getAnalyticsPrice = async () => {
  try {
    const response = await (new Endpoint('addon/plans/ANALYTICS').get());

    if(response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch(e) {
    return Promise.reject(e);
  }
}

export const getAllAnalyticsPrice = async () => {
  try {
    const response = await (new Endpoint('addon/plans/ANALYTICS/prices').get());
    if(response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch(e) {
    return Promise.reject(e);
  }
}

export const getActiveAnalytics = async () => {
  try {
    const response = await (new Endpoint('addon/ANALYTICS').get());

    if(response.data) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch(e) {
    return Promise.reject(e);
  }
}

export const subscribeAnalyticsAddon = async () => {
  try {
    const response = await (new Endpoint('addon/subscribe/ANALYTICS').put());

    if(response.data) {
      return response.data;
    }
    
    return parseErrorResponseDataMessage(response);
  } catch(e) {
    return Promise.reject(e);
  }
}

export const revokeAnalyticsAddon = async () => {
  try {
    const response = await (new Endpoint('addon/cancel/ANALYTICS').put());

    if(response.data) {
      return response.data;
    }
    
      return parseErrorResponseDataMessage(response);
  } catch(e) {
    return Promise.reject(e);
  }
}