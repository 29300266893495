import { createThunkActions } from '../../../utilities';

export const getPaymentToken = createThunkActions(
  '@@payment/GET_PAYMENT_TOKEN'
);
export const getPaymentMethodInfo = createThunkActions(
  '@@payment/GET_PAYMENT_METHOD_INFO'
);
export const setPaymentMethod = createThunkActions(
  '@@payment/SET_PAYMENT_METHOD'
);
export const deletePaymentMethod = createThunkActions(
  '@@payment/DELETE_PAYMENT_METHOD'
);
