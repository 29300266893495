import endsWith from 'lodash/endsWith';

import { resetErrorMessage } from './actions';

const errorReducer = (state = null, action) => {
  const { type, payload } = action;

  if (resetErrorMessage.match(action)) {
    return null;
  }

  if (!endsWith(type, '_FAILURE')) {
    return state;
  }

  if (payload && payload.message && !payload.disableToast) {
    return !payload.isBadStatus
      ? payload.message
      : 'Oops! Something went wrong.';
  }

  return state;
};

export default errorReducer;
