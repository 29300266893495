export const SUBSCRIPTION_PLAN_TYPE = 'SUBSCRIPTION';

export const MONTHLY_PERIOD = 'monthly';
export const QUARTERLY_PERIOD = 'quarterly';
export const ANNUAL_PERIOD = 'annually';

export const MONTHLY_FREQUENCY = 1;
export const QUARTERLY_FREQUENCY = 3;
export const ANNUAL_FREQUENCY = 12;

export const FREQUENCES = {
  [MONTHLY_FREQUENCY]: MONTHLY_PERIOD,
  [QUARTERLY_FREQUENCY]: QUARTERLY_PERIOD,
  [ANNUAL_FREQUENCY]: ANNUAL_PERIOD
};

export const ANALYTICS = 'analytics';