import { createReducer } from '@reduxjs/toolkit';

import {
  filterAndSearchExperienceForOwner,
  filterAndSearchForOwner,
  getById,
  getRoarLinkOpeningCount,
  getRoarScanCount,
  getAnalyticsById,
  getDemos,
  getDemo,
  saveDemo,
  updateDemo,
  getWebARs,
  getWebAR,
  saveWebAR,
  updateWebAR
} from './actions';

const initialState = {
  roars: {
    content: []
  },
  experiences: {
    content: []
  },
  roar: {
    name: '',
    experiences: []
  },
  opensCount: [],
  viewsCount: [],
  currentAnalytics: {},
  demoList: [],
  currentDemo: {
    name: '',
    createdOn: '',
    content: {
      video: {}
    }
  },
  webARList: [],
  currentWebar: {
    name: '',
    video: '',
    targetImage: ''
  },
  errorMessage: ''
};

export default createReducer(initialState, {
  [filterAndSearchForOwner.success]: (state, action) => {
    state.roars = action.payload;
  },
  [filterAndSearchExperienceForOwner.success]: (state, action) => {
    state.experiences = action.payload;
  },
  [getById.success]: (state, action) => {
    state.roar = action.payload;
  },
  [getRoarLinkOpeningCount.success]: (state, action) => {
    state.opensCount = action.payload;
  },
  [getRoarScanCount.success]: (state, action) => {
    state.viewsCount = action.payload;
  },
  [getAnalyticsById.success]: (state, action) => {
    state.currentAnalytics = action.payload;
  },
  [getDemos.success]: (state, action) => {
    state.demoList = action.payload;
  },
  [getDemos.failure]: (state, action) => {
    state.demoList = [];
  },
  [getDemo.success]: (state, action) => {
    state.currentDemo = action.payload;
  },
  [getDemo.failure]: (state, action) => {
    state.currentDemo = {};
  },
  [saveDemo.success]: (state, action) => {
    state.currentDemo = {};
  },
  [updateDemo.success]: (state, action) => {
    state.currentDemo = {};
  },
  [getWebARs.success]: (state, action) => {
    console.log(action.payload);
    state.webARList = action.payload;
  }
});
