import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Footer from '@components/Footer';
import Loader from '@components/Loader';
import LogoIcon from '@components/Logo';
import PageLoader from '@components/PageLoader';
import Topbar from '@components/Topbar';

import { getPath } from '../router-paths';
import { selectCurrentUser } from '@/state/ducks/user';
import { selectActivePlan } from '@/state/ducks/plan';
import roarLogo from '../../assets/images/roar_logo_header.svg';

const DesktopNavigation = React.lazy(() =>
  import('../components/DesktopNavigation')
);
const MobileNavigation = React.lazy(() =>
  import('../components/MobileNavigation')
);

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: '100%'
  },
  logo: {
    marginRight: 25,

    [theme.breakpoints.up('lg')]: {
      marginRight: 54
    },

    '& svg': {
      width: 'auto'
    },

    '& > .MuiIconButton-root': {
      padding: 0
    }
  },
  transparent: {
    backgroundColor: 'transparent',

    '&:hover, &:active, &:focus': {
      backgroundColor: 'transparent'
    }
  },
  content: {
    position: 'relative',
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    overflow: 'auto'
  },
  grow: {
    flexGrow: 1
  },
  logoIcon: {
    width: 98,
    height: 35
  }
}));

const BaseLayout = ({ children, className = '' }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const navigationItems =
  [
    {
      label: t('translation:dashboard'),
      to: getPath('dashboard'),
      private: true
    },
    {
      label: t('translation:my-roars'),
      to: getPath('myRoars'),
      private: true
    },
    {
      label: t('translation:solutions'),
      children: [
        {
          label: t('translation:products'),
          href: `${process.env.REACT_APP_WEBSITE_HOST}/products`,
          target: '_blank'
        },
        {
          label: t('translation:industries'),
          href: `${process.env.REACT_APP_WEBSITE_HOST}/#use-cases`,
          target: '_blank'
        }
      ]
    },
    {
      label: t('translation:pricing'),
      to: getPath('plans')
    },
    {
      label: t('translation:support'),
      href: `${process.env.REACT_APP_WEBSITE_HOST}/support`,
      target: '_blank'
    },
    {
      label: t('translation:more'),
      children: [
        {
          label: t('translation:blog'),
          href: `${process.env.REACT_APP_WEBSITE_HOST}/media-and-blogs`,
          target: '_blank'
        },
        {
          label: t('translation:white-label'),
          href: `${process.env.REACT_APP_WEBSITE_HOST}/white-label`,
          target: '_blank'
        },
        {
          label: t('translation:ar-scanner'),
          href: `${process.env.REACT_APP_WEBSITE_HOST}/scanner`,
          target: '_blank'
        }
      ]
    }
  ];

  const Logo = (
    <div className={classes.logo}>
      <IconButton
        className={classes.transparent}
        component={Link}
        href={process.env.REACT_APP_WEBSITE_HOST}
      >
        {/* <LogoIcon fontSize="large" /> */}
        <img src={roarLogo} className={classes.logoIcon} />
      </IconButton>
    </div>
  );

  return (
    <div className={clsx(classes.root, className)}>
      <Topbar>
        {Logo}
        <React.Suspense fallback={<Loader size={24} />}>
          <Hidden mdDown>
            <DesktopNavigation items={navigationItems} />
          </Hidden>
          <Hidden lgUp>
            <div className={classes.grow} />
            <MobileNavigation items={navigationItems} />
          </Hidden>
        </React.Suspense>
      </Topbar>
      <div className={classes.appBarSpacer} />
      <main className={classes.content}>
        <React.Suspense fallback={<PageLoader />}>{children}</React.Suspense>
      </main>
      <Footer />
    </div>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default BaseLayout;
