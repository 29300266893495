import { parseError } from '@utilities';
import * as actions from './actions';


export const getAnalyticsPriceAsync = () => async(dispatch, getStore, { api }) => {
  dispatch(actions.getAnalyticsPriceAddon.request());

  try {
    const data = await api.addon.getAnalyticsPrice();
    console.log(data);
    dispatch(actions.getAnalyticsPriceAddon.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.getAnalyticsPriceAddon.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const getAllAnalyticsPriceAsync = () => async(dispatch, getStore, { api }) => {
  dispatch(actions.getAllAnalyticsPriceAddon.request());

  try {
    const data = await api.addon.getAllAnalyticsPrice();
    dispatch(actions.getAllAnalyticsPriceAddon.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.getAllAnalyticsPriceAddon.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const getActiveAnalyticsAsync = () => async(dispatch, getStore, { api }) => {
  dispatch(actions.getActiveAnalyticsAddon.request());

  try {
    const data = await api.addon.getActiveAnalytics();
    dispatch(actions.getActiveAnalyticsAddon.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.getActiveAnalyticsAddon.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const subscribeAnalyticsAsync = () => async(dispatch, getStore, { api }) => {
  dispatch(actions.subscribeAnalyticsAddon.request());

  try {
    const data = await api.addon.subscribeAnalyticsAddon();

    dispatch(actions.subscribeAnalyticsAddon.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.subscribeAnalyticsAddon.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const revokeAnalyticsAsync = () => async(dispatch, getStore, { api }) => {
  dispatch(actions.revokeAnalyticsAddon.request());

  try {
    const data = await api.addon.revokeAnalyticsAddon();
    dispatch(actions.revokeAnalyticsAddon.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.revokeAnalyticsAddon.failure(parseError(e)));
    return Promise.reject(e);
  }
}