import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import {
  bindHover,
  bindMenu,
  usePopupState
} from 'material-ui-popup-state/hooks';
import Menu from 'material-ui-popup-state/HoverMenu';
import PropTypes from 'prop-types';

import { ParentPopupState } from './TopbarDropdownMenu';
import DropdownMenuItem from './TopbarDropdownMenuItem';

const useStyles = makeStyles(theme => ({
  menu: {
    top: theme.spacing(-1)
  },
  title: {
    flexGrow: 1
  },
  moreArrow: {
    marginRight: theme.spacing(-1)
  }
}));

const TopbarDropdownSubmenu = React.forwardRef(function DropdownSubmenu(
  { title, className, popupId, variant, children, ...props },
  ref
) {
  const classes = useStyles();
  const parentPopupState = React.useContext(ParentPopupState);
  const popupState = usePopupState({
    popupId,
    variant,
    parentPopupState
  });

  return (
    <ParentPopupState.Provider value={popupState}>
      <DropdownMenuItem
        {...bindHover(popupState)}
        selected={popupState.isOpen}
        ref={ref}
      >
        <ListItemText className={classes.title}>{title}</ListItemText>
        <ChevronRight className={classes.moreArrow} />
      </DropdownMenuItem>
      <Menu
        {...bindMenu(popupState)}
        className={clsx(classes.menu, className)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        {...props}
      >
        {children}
      </Menu>
    </ParentPopupState.Provider>
  );
});

TopbarDropdownSubmenu.propTypes = {
  title: PropTypes.string.isRequired,
  popupId: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['popover', 'popper']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

TopbarDropdownSubmenu.defaultProps = {
  className: '',
  variant: 'popover'
};

export default TopbarDropdownSubmenu;
