import { createReducer } from '@reduxjs/toolkit';

import {
  decrementUsedRecognitionAmount,
  decrementUsedRoarAmount,
  deleteCoupon,
  getActivePlan,
  getActivePlanCoupon,
  getCouponInfo,
  getPlan,
  incrementUsedRecognitionAmount,
  incrementUsedRoarAmount,
  setActivePlan,
  setCoupon,
  setPreviousPlan,
  unsubscribePlan,
  closeProp
} from './actions';

const initialState = {
  plans: [],
  activePlan: {},
  futurePlan: {},
  previousPlan: {},
  coupon: {},
  closeProp: false
};

export default createReducer(initialState, {
  [getPlan.success]: (state, action) => {
    state.plans = action.payload;
  },
  [closeProp.request]: (state, action) => {
    state.closeProp = false;
  },
  [closeProp.success]: (state, action) => {
    state.closeProp = true;
  },
  [getActivePlan.success]: (state, action) => {
    state.activePlan = action.payload.activePlan;
    state.futurePlan = action.payload.scheduledDowngradeTo;
  },
  [setActivePlan.success]: (state, action) => {
    state.previousPlan = state.activePlan;
    state.activePlan = action.payload.activePlan;
    state.futurePlan = action.payload.scheduledDowngradeTo;
  },
  [setPreviousPlan.success]: (state, action) => {
    state.previousPlan = action.payload;
  },
  [incrementUsedRoarAmount]: state => {
    if (state.activePlan.usedRoarAmount) {
      state.activePlan.usedRoarAmount += 1;
    }
  },
  [incrementUsedRecognitionAmount]: state => {
    if (state.activePlan.usedRecognitionAmount) {
      state.activePlan.usedRecognitionAmount += 1;
    }
  },
  [decrementUsedRoarAmount]: state => {
    if (state.activePlan.usedRoarAmount) {
      state.activePlan.usedRoarAmount -= 1;
    }
  },
  [decrementUsedRecognitionAmount]: state => {
    if (state.activePlan.usedRecognitionAmount) {
      state.activePlan.usedRecognitionAmount -= 1;
    }
  },
  [unsubscribePlan.success]: (state, action) => {
    state.activePlan = action.payload.activePlan;
    state.futurePlan = action.payload.scheduledDowngradeTo;
  },
  [getActivePlanCoupon.success]: (state, action) => {
    state.coupon = action.payload;
  },
  [getCouponInfo.success]: (state, action) => {
    state.coupon = action.payload;
  },
  [setCoupon]: (state, action) => {
    state.coupon = action.payload;
  },
  [deleteCoupon]: state => {
    state.coupon = initialState.coupon;
  }
});
