export function setItem(key, value) {
  if (!localStorage) {
    return;
  }

  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    throw new Error('store serialization failed');
  }
}

export function getItem(key) {
  if (!localStorage) {
    return;
  }

  try {
    const serializedValue = localStorage.getItem(key);

    if (!serializedValue) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return JSON.parse(serializedValue);
  } catch (error) {
    throw new Error('store deserialization failed', key);
  }
}

export function removeItem(key) {
  if (!localStorage) {
    return;
  }

  localStorage.removeItem(key);
}
