import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { fade, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.colors.headerColor,
    backgroundColor: theme.colors.headerBackgroundColor,
    boxShadow: `inset 0px -1px 0px ${fade(theme.colors.borderColor, 0.3)}`
  },
  toolbar: {
    padding: theme.spacing(0, 8),
    margin: '0 auto',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 8)
    },

    [theme.breakpoints.up('lg')]: {
      padding: '0 2%'
    }
  }
}));

const Topbar = React.forwardRef(function Topbar(
  { children, className, ...props },
  ref
) {
  const classes = useStyles();

  return (
    <AppBar
      ref={ref}
      className={clsx(classes.root, className)}
      color="inherit"
      {...props}
    >
      <Toolbar className={classes.toolbar}>{children}</Toolbar>
    </AppBar>
  );
});

Topbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Topbar.defaultProps = {
  className: ''
};

export default Topbar;
