import { fade } from '@material-ui/core/styles/colorManipulator';

import colors from '../colors';
import shadows from '../shadows';

export default {
  paper: {
    border: `1px solid ${fade(colors.borderColor, 0.5)}`,
    boxShadow: shadows[2],
    '@media(min-width: 768px)': {
      minWidth: 400
    }
  }
};
