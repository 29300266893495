import isObject from 'lodash/isObject';

import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';

const configEndpoint = new Endpoint('/config');

// eslint-disable-next-line import/prefer-default-export
export const getConfig = async () => {
  try {
    const response = await configEndpoint.get();

    if (isObject(response.data)) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};
