import { createSelector } from '@reduxjs/toolkit';
import assign from 'lodash/assign';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

import { OPENS, VIEWS, VIEWSMONTH, VIEWSTODAY } from './constants';

export const selectRoarsContent = state => state.roar.roars.content;
export const selectRoarsTotalElements = state => state.roar.roars.totalElements;
export const selectRoarsTotalPages = state => state.roar.roars.totalPages;

export const selectExperiencesContent = state => state.roar.experiences.content;
export const selectExperiencesTotalElements = state =>
  state.roar.experiences.totalElements;

export const selectRoar = state => state.roar.roar;
export const selectRoarLinkOpeningCount = state => state.roar.opensCount;
export const selectRoarViewsCount = state => state.roar.viewsCount;
export const selectCurrentAnalytics = state => state.roar.currentAnalytics;

export const selectTotalRoars = createSelector(
  [selectRoarsContent, selectRoarLinkOpeningCount, selectRoarViewsCount],
  (roars, opens, views) => {
    const getCount = (arr, id) => get(find(arr, { roarId: id }), 'count', 0);
    const getCountMonth = (arr, id) => get(find(arr, { roarId: id }), 'countMonth', 0);
    const getCountToday = (arr, id) => get(find(arr, { roarId: id }), 'countToday', 0);

    return map(roars, roar =>
      assign({}, roar, {
        [OPENS]: getCount(opens, roar.id),
        [VIEWS]: getCount(views, roar.id),
        [VIEWSMONTH]: getCountMonth(views, roar.id),
        [VIEWSTODAY]: getCountToday(views, roar.id)
      })
    );
  }
);

export const makeSortedTotalRoarsSelector = () => {
  return createSelector(
    [
      (_, iteratees) => iteratees,
      (_, iteratees, order) => order,
      selectTotalRoars
    ],
    (iteratees, order, roars) => orderBy(roars, iteratees, order)
  );
};
export const selectAllDemo = state => state.roar.demoList;
export const selectCurrentDemo = state => state.roar.currentDemo;
export const selectAllWebar = state => state.roar.webARList;
export const selectCurrentWebar = state => state.roar.currentWebar;