import { useCallback } from 'react';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';

import {
  changeStatusByPageCodeAsync,
  PUBLISHED_WORKFLOW_STATE_CODE
} from '@ducks/roar';

export default function useChangeRoarStatus(status, cb) {
  const dispatch = useDispatch();

  return useCallback(
    async roar => {
      const { pageCode } = roar;

      try {
        await dispatch(changeStatusByPageCodeAsync(pageCode, status));

        if (cb) {
          if (status === PUBLISHED_WORKFLOW_STATE_CODE) {
            ReactGA.event({
              category: 'Create_Roar',
              action: 'published_roar',
              label: roar.arPlatform
            });
          }

          cb(null, roar);
        }
      } catch (e) {
        if (cb) {
          cb(e);
        }
      }
    },
    [cb, dispatch, status]
  );
}
