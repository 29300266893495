import ReactGA from 'react-ga';

import { parseError } from '@utilities';

import * as actions from './actions';
import * as planActions from '../plan/actions';
import * as userActions from '../user/actions';
import { setJwtToken } from '@/utilities';
import { push } from 'connected-react-router';
import { getPath } from '@views/router-paths';

export const getPaymentTokenAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getPaymentToken.request());

  try {
    const token = await api.payment.getPaymentToken();
    dispatch(actions.getPaymentToken.success(token));
    return Promise.resolve(token);
  } catch (e) {
    dispatch(actions.getPaymentToken.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const getPaymentMethodInfoAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getPaymentMethodInfo.request());

  try {
    const info = await api.payment.getPaymentMethodInfo();
    dispatch(actions.getPaymentMethodInfo.success(info));
    return Promise.resolve(info);
  } catch (e) {
    dispatch(actions.getPaymentMethodInfo.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const setPaymentMethodAsync = payload => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.setPaymentMethod.request(payload));

  try {
    const info = await api.payment.setPaymentMethod(payload);
    dispatch(actions.setPaymentMethod.success(info));
    ReactGA.event({
      category: 'Payment',
      action: 'New card was added'
    });
    return Promise.resolve(info);
  } catch (e) {
    dispatch(actions.setPaymentMethod.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const getPaymentCheckoutAsync = payload => async (
  dispatch,
  getStore,
  { api }
) => {
  await dispatch(planActions.getActivePlan.request());
  await dispatch(userActions.signIn.request());
  try {
    const response = await api.payment.getPaymentCheckout(payload);
    setJwtToken(response.token);
    const user = await api.user.getCurrentUser();
    // console.log('User in payment: ', user);
    
    await dispatch(userActions.signIn.success(user));
    await dispatch(planActions.getActivePlan.success(response));
    await dispatch(push(getPath('dashboard')));

  } catch(e) {
    await dispatch(planActions.getActivePlan.failure(parseError(e)));
  }
}

export const deletePaymentMethodAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deletePaymentMethod.request());

  try {
    const info = await api.payment.deletePaymentMethod();
    dispatch(actions.deletePaymentMethod.success(info));
    ReactGA.event({
      category: 'Payment',
      action: 'Card was removed'
    });
    return Promise.resolve(info);
  } catch (e) {
    dispatch(actions.deletePaymentMethod.failure(parseError(e)));
    return Promise.reject(e);
  }
};
