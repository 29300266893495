import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  MARKER_SCAN_CONFIRMATION_TIMEOUT,
  ROAR_ALLOW_PUBLISH
} from '@ducks/roar';
import { selectWsMessage, wsResetMessage } from '@ducks/websocket';

export default function useMarkerScanConfirmation(callback, options = {}) {
  const dispatch = useDispatch();
  const timeoutId = React.useRef(0);
  const message = useSelector(selectWsMessage, shallowEqual);
  const [confirmed, setConfirmed] = React.useState(false);

  const isAllowedPublish =
    message.success === true &&
    message.progressInfo &&
    message.progressInfo.type === ROAR_ALLOW_PUBLISH;

  React.useEffect(() => {
    timeoutId.current = setTimeout(() => {
      if (callback) {
        callback(new Error('timeout'));
      }
    }, options.timeout || MARKER_SCAN_CONFIRMATION_TIMEOUT);

    return () => clearTimeout(timeoutId.current);
  }, [callback, options.timeout]);

  React.useEffect(() => {
    if (!confirmed && isAllowedPublish) {
      clearTimeout(timeoutId.current);
      dispatch(wsResetMessage());
      setConfirmed(true);

      if (callback) {
        callback();
      }
    }
  }, [callback, confirmed, dispatch, isAllowedPublish]);

  return confirmed;
}
