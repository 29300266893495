import endsWith from 'lodash/endsWith';

import { logErrorAsync } from './ducks/error';

const errorLoggerMiddleware = ({ dispatch, getState }) => next => action => {
  if (
    endsWith(action.type, '_FAILURE') &&
    action.payload &&
    action.payload.isBadStatus
  ) {
    const { isBadStatus, ...params } = action.payload;
    const {
      app: { agent }
    } = getState();

    dispatch(
      logErrorAsync(
        {
          error: JSON.stringify(params),
          client: JSON.stringify(agent)
        },
        'api'
      )
    );
  }

  next(action);
};

export default errorLoggerMiddleware;
