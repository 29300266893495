import { useCallback, useEffect, useState } from 'react';

export default function useStepper(initialSteps = []) {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (steps.length === 0 && initialSteps.length > 0) {
      setSteps(initialSteps);
    }
  }, [initialSteps, steps.length]);

  const handleNext = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, []);

  const handleReset = useCallback(() => {
    setActiveStep(0);
  }, []);

  return {
    handleBack,
    handleNext,
    handleReset,
    activeStep,
    steps,
    setSteps,
    setActiveStep
  };
}
