export default {
  fontFamily: ['Open Sans', 'Roboto', 'sans-serif'].join(','),
  htmlFontSize: 16,
  fontSize: 14,
  h1: {
    margin: 0,
    fontSize: '3.5rem',
    fontWeight: 800,
    lineHeight: 1,
    letterSpacing: 1
  },
  h2: {
    fontSize: '3rem',
    fontWeight: 800,
    lineHeight: 1,
    letterSpacing: 1
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: 0
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: 0
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: 0
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: 0
  },
  body1: {
    fontSize: '0.875rem',
    lineHeight: 1.357
  },
  button: {
    lineHeight: 1.357
  }
};
