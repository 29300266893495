import { createThunkActions } from '../../../utilities';

export const loadTeam = createThunkActions('@@team/LOAD_TEAM');
export const createTeam = createThunkActions('@@team/CREATE_TEAM');
export const updateTeam = createThunkActions('@@team/UPDATE_TEAM');
export const deleteTeam = createThunkActions('@@team/DELETE_TEAM');
export const loadTeamMembers = createThunkActions('@@team/LOAD_TEAM_MEMBERS');
export const updateTeamMember = createThunkActions('@@team/UPDATE_TEAM_MEMBER');
export const deleteTeamMember = createThunkActions('@@team/DELETE_TEAM_MEMBER');
export const deleteBulkMember = createThunkActions('@@team/DELETE_BULK_MEMBER');
export const inviteUserToTeam = createThunkActions(
  '@@team/INVITE_USER_TO_TEAM'
);
export const approveTeamMember = createThunkActions(
  '@@team/APPROVE_TEAM_MEMBER'
);
export const resendInviteEmail = createThunkActions(
  '@@team/RESEND_INVITE_EMAIL'
);
export const resetPassword = createThunkActions(
  '@@team/RESET_PASSWORD_MEMBER'
);
export const getMyInfoOnTeam = createThunkActions( '@@team/GET_MY_INFO_ON_TEAM');