import { createReducer } from '@reduxjs/toolkit';

import { getBillingInfo, setBillingInfo } from './actions';

const initialState = {};

export default createReducer(initialState, {
  [getBillingInfo.success]: (_, action) => action.payload,
  [setBillingInfo.success]: (_, action) => action.payload
});
