import { createReducer } from '@reduxjs/toolkit';
import DeviceDetector from 'device-detector-js';

import { getConfig, setConfig } from './actions';

const deviceDetector = new DeviceDetector();

const initialState = {
  config: {},
  agent: deviceDetector.parse(navigator.userAgent)
};

const appReducer = createReducer(initialState, {
  [getConfig.success]: (state, action) => {
    state.config = action.payload;
  },
  [setConfig]: (state, action) => {
    state.config = action.payload;
  }
});

export default appReducer;
