import { parseError } from '@utilities';

import * as actions from './actions';

export const getBillingInfoAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getBillingInfo.request());

  try {
    const info = await api.billing.getInfo();
    dispatch(actions.getBillingInfo.success(info));
    return Promise.resolve(info);
  } catch (e) {
    dispatch(actions.getBillingInfo.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const setBillingInfoAsync = payload => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.setBillingInfo.request(payload));

  try {
    const info = await api.billing.setInfo(payload);
    dispatch(actions.setBillingInfo.success(info));
    return Promise.resolve(info);
  } catch (e) {
    dispatch(actions.setBillingInfo.failure(parseError(e)));
    return Promise.reject(e);
  }
};
