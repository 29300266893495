import ReactGA from 'react-ga';
import { push } from 'connected-react-router';
import assign from 'lodash/assign';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';

import { getPath } from '@views/router-paths';

import { parseError, setGuestUser, setJwtToken } from '../../../utilities';

import * as actions from './actions';

export const getCurrentUserAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getCurrentUser.request());

  try {
    const data = await api.user.getCurrentUser();
    dispatch(actions.getCurrentUser.success(data));
    return Promise.resolve(data);
  } catch (e) {
    dispatch(actions.getCurrentUser.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const confirmEmailAsync = token => async (
  dispatch,
  getStore,
  { api, toast }
) => {
  dispatch(actions.confirmEmail.request(token));

  try {
    const data = await api.user.confirmEmail(token);
    setJwtToken(data.token);
    dispatch(actions.confirmEmail.success());
    toast.success('Your email successfully verified');
    const user = await api.user.getCurrentUser();
    dispatch(actions.signIn.success(user));
  } catch (e) {
    dispatch(actions.confirmEmail.failure(parseError(e)));
  }
};

export const declineEmailAsync = token => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.declineEmail.request(token));

  try {
    const data = await api.user.declineEmail(token);
    dispatch(actions.declineEmail.success());
    return data;
  } catch (e) {
    dispatch(actions.declineEmail.failure(parseError(e)));
  }
};

export const updateCurrentUserAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.updateCurrentUser.request());
  const { user } = getStore();

  const updatedParams = reduce(
    user,
    (result, value, key) => {
      const diffParams = {};

      if (params[key] && !isEqual(user[key], params[key])) {
        diffParams[key] = params[key];
      }

      return assign({}, result, diffParams);
    },
    {}
  );

  try {
    await api.user.updateCurrentUser(updatedParams);
    dispatch(actions.updateCurrentUser.success(updatedParams));
  } catch (e) {
    dispatch(
      actions.updateCurrentUser.failure({ message: e.message, code: e.code })
    );
  }
};

export const deleteCurrentUserAsync = reason => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deleteCurrentUser.request());

  try {
    const data = await api.user.deleteCurrentUser(reason);
    dispatch(actions.deleteCurrentUser.success(data));
    return Promise.resolve(data);
  } catch (e) {
    dispatch(actions.deleteCurrentUser.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const resendConfirmationEmailAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.resendConfirmationEmail.request());

  try {
    await api.user.resendConfirmationEmail();
    dispatch(actions.resendConfirmationEmail.success());
    return Promise.resolve(true);
  } catch (e) {
    dispatch(actions.resendConfirmationEmail.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const signInPriceAsync = ({ username, planId, period }) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.signInPrice.request());
  try {
    const data = await api.user.emailCheck({ username });
    const guestData = {
      username,
      planId
    };
    setGuestUser(guestData);
    if (data.success) {
      await dispatch(push(getPath('signIn')));
    } else {
      await dispatch(push(getPath('buy', planId, period)));
    }
    await dispatch(actions.signInPrice.success());
  } catch (e) {
    dispatch(actions.signInPrice.failure(parseError(e)));
  }
};

export const signInAsync = ({ username, password }) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.signIn.request());

  try {
    const token = await api.user.signIn({ username, password });
    setJwtToken(token);
    const user = await api.user.getCurrentUser();
    window.clevertap.onUserLogin.push({
      Site: {
        Name: user.firstName, // String
        Identity: user.id, // String or number
        Email: user.email // Email address of the user
      }
    });
    dispatch(actions.signIn.success(user));
  } catch (e) {
    dispatch(actions.signIn.failure(parseError(e)));
  }
};

export const facebookAuthAsync = ({ token }) => async (
  dispatch,
  _getStore,
  { api }
) => {
  dispatch(actions.facebookAuth.request());
  try {
    const response = await api.user.facebookAuth({
      token
    });
    dispatch(actions.signUp.success(response.user));

    if (response.token) {
      setJwtToken(response.token);
      await dispatch(push(getPath('dashboard')));
    } else {
      await dispatch(push(getPath('signIn')));
    }
    return Promise.resolve(response.user);
  } catch (error) {
    dispatch(actions.facebookAuth.failure(parseError(error)));
    return Promise.reject(error);
  }
};

export const signUpAsync = ({
  email,
  lastName,
  password,
  isShowTutorial
}) => async (dispatch, getStore, { api }) => {
  dispatch(actions.signUp.request());

  try {
    const { user, token } = await api.user.signUp({
      email,
      lastName,
      password,
      isShowTutorial
    });
    dispatch(actions.signUp.success(user));
    ReactGA.event({
      category: 'Registration',
      action: 'Registered'
    });

    if (token) {
      setJwtToken(token);
      await dispatch(push(getPath('dashboard')));
    } else {
      await dispatch(push(getPath('signIn')));
    }
    return Promise.resolve(user);
  } catch (e) {
    dispatch(actions.signUp.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const easySignupAsync = ({ email }) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.signUp.request());

  try {
    const { user, token } = await api.user.signUp({
      email
    });
    dispatch(actions.signUp.success(user));
    ReactGA.event({
      category: 'Registration',
      action: 'Registered'
    });

    if (token) {
      setJwtToken(token);
      await dispatch(push(getPath('dashboard')));
    } else {
      await dispatch(push(getPath('signIn')));
    }
    return Promise.resolve(user);
  } catch (e) {
    dispatch(actions.signUp.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const signOutAsync = () => async (dispatch, getStore, { api }) => {
  dispatch(actions.signOut.request());

  try {
    const data = await api.user.signOut();
    const { user } = getStore();
    window.clevertap.event.push('Log Out', {
      Email: user.email,
      Identity: user.id,
      Name: user.firstName
    });

    dispatch(actions.signOut.success(data));
  } catch (e) {
    dispatch(actions.signOut.failure(parseError(e)));
  }
};

export const updateCurrentUserPasswordAsync = dto => async (
  dispatch,
  getStore,
  { api, toast }
) => {
  dispatch(actions.updateCurrentUserPassword.request());
  const { user } = getStore();

  try {
    const data = await api.user.updateCurrentUserPassword(dto);
    dispatch(actions.updateCurrentUserPassword.success(data));
    dispatch(signInAsync({ username: user.email, password: dto.newPassword }));
    toast.success('Password successfully updated');
  } catch (e) {
    dispatch(actions.updateCurrentUserPassword.failure(parseError(e)));
  }
};

export const recoveryPasswordRequestAsync = email => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.recoveryPasswordRequest.request());

  try {
    const data = await api.user.recoveryPasswordRequest(email);
    dispatch(actions.recoveryPasswordRequest.success(data));
    return Promise.resolve(data);
  } catch (e) {
    dispatch(actions.recoveryPasswordRequest.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const recoveryPasswordConfirmAsync = (password, token) => async (
  dispatch,
  getStore,
  { api, toast }
) => {
  dispatch(actions.recoveryPasswordConfirm.request());

  try {
    const data = await api.user.recoveryPasswordConfirm(password, token);
    dispatch(actions.recoveryPasswordConfirm.success(data));
    toast.success('Your password has been updated!');
    return Promise.resolve(data);
  } catch (e) {
    dispatch(actions.recoveryPasswordConfirm.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const setReceiveReceiptsAsync = value => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.setReceiveReceipts.request(value));

  try {
    const data = await api.user.setReceiveReceipts(value);
    dispatch(actions.setReceiveReceipts.success(data));
    return Promise.resolve(data);
  } catch (e) {
    dispatch(actions.setReceiveReceipts.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const getInvoicesAsync = (from, to) => async (
  dispatch,
  getStore,
  { api }
) => {
  try {
    const data = await api.user.getInvoices(from, to);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadInvoiceAsync = transactionId => async (
  dispatch,
  getStore,
  { api }
) => {
  try {
    const data = await api.user.downloadInvoice(transactionId);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};
