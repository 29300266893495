export default [
  'none',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '4px 8px 8px rgba(0, 0, 0, 0.02)',
  '0px 0px 20px rgba(70, 70, 72, 0.10193)',
  '0px 8px 32px #BDD7F0',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)',
  '0px 1px 4px rgba(0, 0, 0, 0.154721)'
];
