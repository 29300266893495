import MuiBackdrop from './MuiBackdrop';
import MuiButton from './MuiButton';
import MUIDataTable from './MUIDataTable';
import MUIDataTableSearch from './MUIDataTableSearch';
import MUIDataTableToolbar from './MUIDataTableToolbar';
import MuiDialog from './MuiDialog';
import MuiDialogActions from './MuiDialogActions';
import MuiDialogContentText from './MuiDialogContentText';
import MuiDialogTitle from './MuiDialogTitle';
import MuiDivider from './MuiDivider';
import MuiFormLabel from './MuiFormLabel';
import MuiIconButton from './MuiIconButton';
import MuiInput from './MuiInput';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiList from './MuiList';
import MuiListItem from './MuiListItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSelect from './MuiSelect';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTableCell from './MuiTableCell';
import MuiTablePagination from './MuiTablePagination';
import MuiTableRow from './MuiTableRow';
import MuiTableSortLabel from './MuiTableSortLabel';

const createOverrides = theme => ({
  MuiButton: MuiButton(theme),
  MuiIconButton: MuiIconButton(theme),
  MuiFormLabel,
  MuiInput,
  MuiInputBase,
  MuiOutlinedInput: MuiOutlinedInput(theme),
  MuiInputLabel,
  MuiSelect,
  MuiList,
  MuiListItem,
  MuiSvgIcon,
  MuiTableRow,
  MuiTableCell: MuiTableCell(theme),
  MuiTableSortLabel,
  MuiTablePagination,
  MUIDataTable: MUIDataTable(theme),
  MUIDataTableToolbar,
  MUIDataTableSearch: MUIDataTableSearch(theme),
  MuiDialog,
  MuiDialogActions: MuiDialogActions(theme),
  MuiDialogContentText,
  MuiDialogTitle,
  MuiBackdrop,
  MuiDivider: MuiDivider(theme)
});

export default createOverrides;
