import ReactGA from 'react-ga';
import { shallowEqual, useSelector } from 'react-redux';

import { selectActivePlan, selectPlans } from '@ducks/plan/selectors';
import { parseError } from '@utilities';

import * as actions from './actions';
import { extractPlanTitle } from './utilities';

export const getPlanAsync = () => async (dispatch, getStore, { api }) => {
  dispatch(actions.getPlan.request());

  try {
    const data = await api.plan.getPlan();
    dispatch(actions.getPlan.success(data));
    return Promise.resolve(data);
  } catch (e) {
    dispatch(actions.getPlan.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const closeCreateARDialog = () => async (dispatch) => {
  dispatch(actions.closeProp.success())
}

export const openCreateARDialog = () => (dispatch) => {
  dispatch(actions.closeProp.request());
}

export const getActivePlanAsync = () => async (dispatch, getStore, { api }) => {
  dispatch(actions.getActivePlan.request());

  try {
    const plan = await api.plan.getActivePlan();
    dispatch(actions.getActivePlan.success(plan));
    return Promise.resolve(plan);
  } catch (e) {
    dispatch(actions.getActivePlan.failure(parseError(e)));
  }
};

export const setActivePlanAddOnAsync = (plan, coupon, addonName) => async(
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.setActivePlan.request(plan));

  try {
    const { user } = getStore();

    if (!user.confirmed) {
      ReactGA.event({
        category: 'Payment',
        action: `${extractPlanTitle(plan)} was requested`
      });
    }

    const response = await api.plan.setActivePlanAddOn(plan, coupon, addonName);
    dispatch(actions.setActivePlan.success(response));

    let actionModel;
    if (response.scheduledDowngradeTo) {
      actionModel = response.scheduledDowngradeTo;
    } else {
      actionModel = response.activePlan;
    }

    if (user.confirmed) {
      console.log('GA happened');
      ReactGA.event({
        category: 'Payment',
        action: `${extractPlanTitle(actionModel)} was bought`
      });
    }

    return Promise.resolve(response);
  } catch (e) {
    dispatch(actions.setActivePlan.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const setActivePlanAsync = (plan, coupon) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.setActivePlan.request(plan));

  try {
    const { user } = getStore();

    if (!user.confirmed) {
      ReactGA.event({
        category: 'Payment',
        action: `${extractPlanTitle(plan)} was requested`
      });
    }

    const response = await api.plan.setActivePlan(plan, coupon);
    if (!response.scheduledDowngradeTo) {
      const currentPlan = useSelector(selectActivePlan, shallowEqual);
      dispatch(actions.setPreviousPlan.success(currentPlan));
    }
    dispatch(actions.setActivePlan.success(response));

    let actionModel;
    if (response.scheduledDowngradeTo) {
      actionModel = response.scheduledDowngradeTo;
    } else {
      actionModel = response.activePlan;
    }

    console.log(response);
    if (user.confirmed) {
      ReactGA.event({
        category: 'Payment',
        action: `${extractPlanTitle(actionModel)} was bought`
      });

      const { plan } = getStore();
      let currentPlan = response.activePlan;
      let dataLayer = window.dataLayer;
      let currentPlanDetail = null;
      plan.plans.map(elm => {
        if(elm.id === currentPlan.planId) {
          currentPlanDetail = elm;
        }
      });
      if(currentPlanDetail && response.purchaseInfo !== undefined && response.purchaseInfo !== null && response.purchaseInfo.transactionId !== null && response.purchaseInfo.transactionId !== undefined) {
        dataLayer.push({
          'event': 'transaction',
          'theroar': {
            'purchase': {
              'actionField': {
                'id': response.purchaseInfo.transactionId,        // Transaction ID. Required
              },
            'products': [{          // List of productFieldObjects.
                'name': currentPlanDetail.title,  // Name or ID is required.
                'id': currentPlanDetail.id,
                'price': currentPlanDetail.price,
                'frequency': currentPlanDetail.billingFrequency,
               }]
            }
          }
        });
      }
      
    }

    return Promise.resolve(response);
  } catch (e) {
    dispatch(actions.setActivePlan.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const retryPaymentAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.retryCheck.request());
  try {
    const response = await api.plan.retryPayment();
    dispatch(actions.retryCheck.success(response));
  } catch(e) {
    dispatch(actions.retryCheck.failure(parseError(e)));
  }
}

export const getActivePlanCouponAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getActivePlanCoupon.request());

  try {
    const coupon = await api.plan.getActivePlanCoupon();
    dispatch(actions.getActivePlanCoupon.success(coupon));
  } catch (e) {
    dispatch(actions.getActivePlanCoupon.failure(parseError(e)));
  }
};

export const getCouponInfoAsync = couponName => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getCouponInfo.request(couponName));

  try {
    const info = await api.plan.getCouponInfo(couponName);
    dispatch(actions.getCouponInfo.success(info));
  } catch (e) {
    dispatch(actions.getCouponInfo.failure(parseError(e)));
  }
};

export const unsubscribePlanAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.unsubscribePlan.request());

  try {
    const data = await api.plan.unsubscribePlan();
    dispatch(actions.unsubscribePlan.success(data));
    ReactGA.event({
      category: 'Payment',
      action: 'Subscription was stopped'
    });
  } catch (e) {
    dispatch(actions.unsubscribePlan.failure(parseError(e)));
  }
};
