import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageLoader from '@components/PageLoader';
import { getConfigAsync } from '@ducks/app';
import { selectIsSignedIn } from '@ducks/user';

/**
 * @todo add fallback for failed config fetching
 */
export default function AppConfigFetcher({ children }) {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(selectIsSignedIn);
  const [canRender, setCanRender] = React.useState(false);

  React.useEffect(() => {
    if (isSignedIn) {
      dispatch(getConfigAsync())
        .then(() => {
          setCanRender(true);
        })
        .catch(() => {
          setCanRender(true);
        });
    }
  }, [dispatch, isSignedIn]);

  if (isSignedIn && !canRender) {
    return <PageLoader />;
  }

  return children;
}
