import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';

const baseURL = 'marker';

const getRateEndpoint = new Endpoint(
  ({ pageCode = '' }) => `${baseURL}/rate/${pageCode}`
);

// eslint-disable-next-line import/prefer-default-export
export const getRate = async roar => {
  try {
    const response = await getRateEndpoint.get({
      uriParams: { pageCode: roar.pageCode || roar.roarCode }
    });

    if (response.data && response.data.success) {
      return response.data.rate;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};
