import { parseError } from '@utilities';

import * as actions from './actions';

// eslint-disable-next-line import/prefer-default-export
export const getConfigAsync = () => async (dispatch, getStore, { api }) => {
  dispatch(actions.getConfig.request());

  try {
    const config = await api.app.getConfig();
    dispatch(actions.getConfig.success(config));
    return Promise.resolve(config);
  } catch (e) {
    dispatch(actions.getConfig.failure(parseError(e)));
    return Promise.reject(e);
  }
};
