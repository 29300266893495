import { push } from 'connected-react-router';
import some from 'lodash/some';

import { deleteCurrentUser, signIn, signOut, signUp } from '@ducks/user';
import { wsDisconnect } from '@ducks/websocket';
import {
  ERROR_CODE_MISSED_USER_ROLE,
  ERROR_CODE_TOKEN_IS_INVALID,
  removeJwtToken
} from '@utilities';

import { getPath } from '../views/router-paths';

// List of exceptions that are skipped by middleware.
const exceptions = [signIn.failure, signUp.failure];

function isMatchErrorCode(code) {
  return (
    code === ERROR_CODE_MISSED_USER_ROLE || code === ERROR_CODE_TOKEN_IS_INVALID
  );
}

const authMiddleware = ({ dispatch }) => next => action => {
  const isException = some(exceptions, exception => exception.match(action));

  // Client-side signOut
  if (action.payload && !isException && isMatchErrorCode(action.payload.code)) {
    dispatch(signOut.success());
    dispatch(push(getPath('signIn')));
  }

  if (
    signOut.success.match(action) ||
    deleteCurrentUser.success.match(action)
  ) {
    removeJwtToken();
    dispatch(wsDisconnect());
  }

  next(action);
};

export default authMiddleware;
