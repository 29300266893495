import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 41,
    height: 41,
    border: `2.5px solid ${theme.palette.primary.main}`
  },
  button: {
    outline: 'none',
    border: 'none'
  }
}));

const TopbarAvatar = ({ children, user = {}, ...props }) => {
  const classes = useStyles();

  return (
    <PopupState variant="popover" popupId="notifications" {...props}>
      {popupState => (
        <div>
          <Button
            className={classes.button}
            {...bindTrigger(popupState)}
            endIcon={<ExpandMoreIcon />}
          >
            <AccountCircleIcon />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Box p={2}>{children(popupState)}</Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

TopbarAvatar.propTypes = {
  children: PropTypes.elementType.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string
  })
};

export default TopbarAvatar;
