import { fade } from '@material-ui/core/styles/colorManipulator';

import colors from '../colors';

export default theme => ({
  root: {
    padding: theme.spacing(1.5),
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.1)
    },

    '&:not(:hover):active': {
      background: fade(theme.palette.primary.main, 0.7),

      '& .MuiSvgIcon-root': {
        fill: colors.white
      }
    },

    '&.Mui-disabled': {
      opacity: 0.5
    }
  }
});
