import { createAction } from '@reduxjs/toolkit';

import { createThunkActions } from '../../../utilities';

export const closeProp = createThunkActions('@@plan/CLOSE_PROP');
export const getPlan = createThunkActions('@@plan/GET_PLAN');
export const getActivePlan = createThunkActions('@@plan/GET_ACTIVE_PLAN');
export const setActivePlan = createThunkActions('@@plan/SET_ACTIVE_PLAN');
export const setPreviousPlan = createThunkActions('@@plan/SET_PREVIOUS_PLAN');
export const getActivePlanCoupon = createThunkActions(
  '@@plan/GET_ACTIVE_PLAN_COUPON'
);
export const getCouponInfo = createThunkActions('@@plan/GET_COUPON_INFO');
export const unsubscribePlan = createThunkActions('@@plan/UNSUBSCRIBE_PLAN');
export const setCoupon = createAction('@@plan/SET_COUPON');
export const deleteCoupon = createAction('@@plan/DELETE_COUPON');
export const incrementUsedRoarAmount = createAction(
  '@@plan/INCREMENT_USED_ROAR_AMOUNT'
);
export const incrementUsedRecognitionAmount = createAction(
  '@@plan/INCREMENT_USED_RECOGNITION_AMOUNT'
);
export const decrementUsedRoarAmount = createAction(
  '@@plan/DECREMENT_USED_ROAR_AMOUNT'
);
export const decrementUsedRecognitionAmount = createAction(
  '@@plan/DECREMENT_USED_RECOGNITION_AMOUNT'
);
export const retryCheck = createThunkActions('@@plan/RETRY_CHECK');