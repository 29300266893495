import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';

const billingContactEndpoint = new Endpoint('billingContact/me');

export const getInfo = async () => {
  try {
    const response = await billingContactEndpoint.get();

    if (response.data && response.data.success && response.data.data) {
      return response.data.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const setInfo = async payload => {
  try {
    const response = await billingContactEndpoint.put(payload);

    if (response.data && response.data.success && response.data.data) {
      return response.data.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};
