import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';

import StandardDialogTitle from './StandardDialogTitle';

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: 'center',
    padding: theme.spacing(4)
  },
  actions: {
    justifyContent: 'center'
  },
  continueBtn: {
    minWidth: 200,
    minHeight: 41
  }
}));

const StandardDialog = ({
  title = '',
  content = null,
  actions = null,
  onClose,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth="md" scroll="body" onClose={onClose} fullWidth {...props}>
      <StandardDialogTitle onClose={onClose} className={classes.title}>
        {isString(title) ? (
          <Typography variant="h3">{title}</Typography>
        ) : (
          title
        )}
      </StandardDialogTitle>
      {content && (
        <DialogContent>
          <div className={classes.content}>{content}</div>
        </DialogContent>
      )}
      {actions && (
        <DialogActions className={classes.actions}>{actions}</DialogActions>
      )}
    </Dialog>
  );
};

StandardDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.string,
    PropTypes.node
  ]),
  content: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.string,
    PropTypes.node
  ]),
  actions: PropTypes.element
};

export default StandardDialog;
