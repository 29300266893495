import qs from 'querystring';
import axios from 'axios';

import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';
import { EndPointBack } from '@utilities/api-backend';
import { parseError } from '@/utilities';

const baseURL = 'roar';
const roarExperienceURL = 'experience';
const arContentURL = 'content';

const deleteRoarEndpoint = new Endpoint(
  ({ pageCode = '' }) => `${baseURL}/${pageCode}`
);
const filterAndSearchEndpoint = new Endpoint(`${baseURL}/filterAndSearch`);
const filterAndSearchForOwnerEndpoint = new Endpoint(
  `${baseURL}/filterAndSearchForOwner`
);
const getByPageCodeEndpoint = new Endpoint(
  ({ pageCode = '' }) => `${baseURL}/byPageCode/${pageCode}`
);
const getByIdEndpoint = new Endpoint(
  ({ roarId = '' }) => `${baseURL}/dynamo/byRoarId/${roarId}`
);
const getAnalyticsByIdEndpoint = new Endpoint(
  ({ roarId = '', from = 0, to = 190000000 }) => `${baseURL}/analytics/${roarId}?from=${from}&to=${to}`
);
const filterAndSearchExperienceForOwnerEndpoint = new Endpoint(
  `${roarExperienceURL}/filterAndSearchForOwner`
);
const changeStatusByPageCodeEndpoint = new Endpoint(
  ({ pageCode = '' }) => `${baseURL}/${pageCode}/changeStatusByPageCode`
);
const arContentExperienceEndpoint = new Endpoint(
  ({ roarId = '', experienceId = '' }) =>
    `${arContentURL}/${roarId}/${experienceId}`
);
const arContentExperienceActiveEndpoint = new Endpoint(
  ({ roarId = '', experienceId = '' }) =>
    `${arContentURL}/${roarId}/${experienceId}/active`
);
const roarLinkOpeningCountEndpoint = new Endpoint(
  `${baseURL}/roarLinkOpeningCount`
);
const roarScanCountEndpoint = new Endpoint(`${baseURL}/roarScanCount`);

const campaignModifyEndpoint = new Endpoint(({ campaignId = '' }) => `${baseURL}/campaign/${campaignId}`);

const campaignEndpoint = new Endpoint(`${baseURL}/campaign`);

export const filterAndSearch = async params => {
  try {
    const response = await filterAndSearchEndpoint.get({ params });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const filterAndSearchForOwner = async params => {
  try {
    const response = await filterAndSearchForOwnerEndpoint.get({ params });

    if (response.data && response.data.success && response.data.page) {
      return response.data.page;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const filterAndSearchExperienceForOwner = async params => {
  try {
    const response = await filterAndSearchExperienceForOwnerEndpoint.get({
      params
    });

    if (response.data && response.data.success && response.data.page) {
      return response.data.page;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getByPageCode = async pageCode => {
  try {
    const response = await getByPageCodeEndpoint.get({
      uriParams: { pageCode }
    });

    if (response.data && response.data.success && response.data.roar) {
      return response.data.roar;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getById = async roarId => {
  try {
    const response = await getByIdEndpoint.get({
      uriParams: { roarId }
    });

    if (response.data && response.data.success && response.data.roar) {
      return response.data.roar;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAnalyticsById = async (roarId, from, to) => {
  try {
    const response = await getAnalyticsByIdEndpoint.get({
      uriParams: { roarId, from, to }
    });

    if (response.data && response.data.success && response.data.data && response.data.data !== null) {
      return response.data.data;
    } else {
      return {
        totalViews: 0,
        totalUsers: 0,
        avgDurationSeconds: 0,
        avgViewsPerUser: 0,
        newUsers: 0,
        repeating: 0,
        activity: {}
      }
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getRoarLinkOpeningCount = async (roarIds = []) => {
  try {
    const response = await roarLinkOpeningCountEndpoint.get({
      params: {
        roarIds
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });

    if (response.data && response.data.success && response.data.roarLogs) {
      return response.data.roarLogs;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getRoarScanCount = async (roarIds = []) => {
  try {
    const response = await roarScanCountEndpoint.get({
      params: {
        roarIds
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });

    if (response.data && response.data.success && response.data.roarLogs) {
      return response.data.roarLogs;
    }
    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * @param {*} pageCode
 * @param {*} status ARCHIVED, DRAFT, DRAFT_MARKER_READY, PUBLISHED
 */
export const changeStatusByPageCode = async (pageCode, status) => {
  try {
    const response = await changeStatusByPageCodeEndpoint.put(
      {},
      {
        uriParams: { pageCode },
        params: { status }
      }
    );

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteByPageCode = async pageCode => {
  try {
    const response = await deleteRoarEndpoint.delete({
      uriParams: { pageCode }
    });

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteExperience = async ({ roarId, experienceId }) => {
  try {
    const response = await arContentExperienceEndpoint.delete({
      uriParams: { roarId, experienceId }
    });

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const makeExperienceActive = async ({ roarId, experienceId }) => {
  try {
    const response = await arContentExperienceActiveEndpoint.put(
      {},
      {
        uriParams: { roarId, experienceId }
      }
    );

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addCampaign = async (params) => {
  try {
    const response = await campaignEndpoint.post(params);
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

function dataURLtoFile(dataurl, filename) {
  let bstr = atob(dataurl),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: "text/plain",
  });
}

export const addWebAR = async (params) => {
  try {
    let endpoint = new Endpoint(`webarentry`);
    const response = await endpoint.post(params);


    if (response.data && response.data.success) {
      let file1, file2, file3, result;
      let formData = new FormData();

      formData.append(
        "file",
        params.imageUploadParams
      );
      // await axios.post(process.env.REACT_APP_API_BACKEND + '/gallery/upload',
      await axios.post(process.env.REACT_APP_WEBSITE_HOST + '/gallery/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
          },
          mode: 'cors'
        }
      ).then(function (res) {

        file1 = dataURLtoFile(res.data[0], response.data.webArEntry.id + '.fset')
        file2 = dataURLtoFile(res.data[1], response.data.webArEntry.id + '.fset3')
        file3 = dataURLtoFile(res.data[2], response.data.webArEntry.id + '.iset')

        result = {
          data: response.data,
          files: [file1, file2, file3]
        }
      }).catch(function (error) {
        console.log(error)
      })
      return result;
    }
    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const updateCampaign = async (params) => {
  try {
    const response = await campaignModifyEndpoint.put(
      params,
      {
        uriParams: { campaignId: params.id },
      }
    );

    if (response.data && response.data.success) {
      return response.data;
    }

    return Promise.reject(new Error(response.data.message || 'Failure'));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateWebAR = async (params) => {
  try {
    const endpoint = new Endpoint(`webarentry`);

    const response = await endpoint.put(params);
    if (response.data && response.data.success) {
      return response.data;
    }
    return Promise.reject(new Error(response.data.message || 'Failure'));
  } catch (e) {
    return Promise.reject(e);
  }
}

export const uploadFile = async (params) => {
  try {
    const formData = new FormData();
    formData.append(
      "file",
      params
    );
    const campaignUploadEndpoint = new Endpoint(`${baseURL}/campaign/upload`);

    const response = await campaignUploadEndpoint.post(formData);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const uploadWebARFile = async (params) => {
  console.log("upload the files console: ", params)
  try {
    const formData = new FormData();
    params.files.map((item, index) => {
      formData.append(
        "file",
        params.files[index]
      )
    })

    const endpoint = new Endpoint(`webarentry/upload/` + params.data.webArEntry.id + `?keepNames=true`);
    const response = await endpoint.post(formData);

    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getCampaignById = async ({ campaignId }) => {
  try {
    let url = baseURL + `/campaign/${campaignId}`;
    let endpoint = new Endpoint(url);
    const response = await endpoint.get();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getWebARById = async ({ webarId }) => {
  try {
    let url = `webarentry/${webarId}`;
    let endpoint = new Endpoint(url);
    const response = await endpoint.get();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getCampaignList = async () => {
  try {
    const response = await campaignEndpoint.get();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getWebARList = async () => {
  try {
    let endpoint = new Endpoint(`webarentry`);
    const response = await endpoint.get();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const deleteCampaign = async (campaignIds) => {
  try {
    let url = baseURL + '/campaign?';
    campaignIds.map(campaignId => {
      url += `id=${campaignId}&`;
      return campaignId;
    });

    const bulkDeleteEndpoint = new Endpoint(() => url);
    const response = await bulkDeleteEndpoint.delete();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteWebAR = async (webARId) => {
  try {
    const endpoint = new Endpoint(`webarentry/${webARId}`);
    const response = await endpoint.delete();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getAnalyticCampaign = async (params) => {
  try {
    let url = baseURL + `/campaign/analytics/${params.id}?from=${params.from}&to=${params.to}`;
    const campaignAnalyticEndpoint = new Endpoint(() => url);
    const response = await campaignAnalyticEndpoint.get();

    if (response.data && response.data.success && response.data.data && response.data.data !== null) {
      return response.data;
    }

    return {
      success: true,
      data: {
        totalViews: 0,
        totalUsers: 0,
        avgDurationSeconds: 0,
        avgViewsPerUser: 0,
        newUsers: 0,
        repeating: 0,
        activity: {}
      }
    }
  } catch (e) {
    return Promise.reject(e);
  }
}