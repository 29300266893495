import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import PageLoader from '@components/PageLoader';
import { getCurrentUserAsync, selectCurrentUser } from '@ducks/user';
import { getJwtToken } from '@utilities';

export default function UserAutoAuthenticator({ children }) {
  const token = getJwtToken();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser, shallowEqual);
  const [canRender, setCanRender] = React.useState(false);
  const currentUrl = window.location.pathname;

  React.useEffect(() => {
    if (!currentUrl.includes('verify')) {
      if (!user.id && token) {
        dispatch(getCurrentUserAsync())
          .then(() => {
            setCanRender(true);
          })
          .catch(() => {
            setCanRender(true);
          });
      }
    } else {
      setCanRender(true);
    }
  }, [dispatch, token, user.id, currentUrl]);

  if (!user.id && token && !canRender) {
    return <PageLoader />;
  }

  return children;
}
