import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import RecognitionsLeftDialog from '@components/RecognitionsLeftDialog';
import { selectActivePlan } from '@ducks/plan';
import { RECOGNITIONS_LOW_LEVEL } from '@utilities';

import useModal from './useModal';
import { getItem, setItem } from '@/services/local-storage-service';

const prevUsedRecognitionAmount = { current: null };

export default function useRecognitions() {
  const dispatch = useDispatch();
  const activePlan = useSelector(selectActivePlan, shallowEqual);
  const hasPlan = !isEmpty(activePlan);
  const { showModal } = useModal();

  useEffect(() => {
    if (
      hasPlan &&
      !isNull(activePlan.usedRecognitionAmount) &&
      prevUsedRecognitionAmount.current !== activePlan.usedRecognitionAmount
    ) {
      let recognitionAlertDone = getItem('isRecognitionAlertDone');
      const left =
        activePlan.recognitionAmount - activePlan.usedRecognitionAmount;
      prevUsedRecognitionAmount.current = activePlan.usedRecognitionAmount;

      if (left <= 5 && !recognitionAlertDone) {
        setItem('isRecognitionAlertDone', true);
        showModal(RecognitionsLeftDialog, { count: left });
      } else if(left > 5) {
        setItem('isRecognitionAlertDone', false);
      }
    }
  }, [
    activePlan.recognitionAmount,
    activePlan.usedRecognitionAmount,
    dispatch,
    hasPlan,
    showModal
  ]);
}
