import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { selectAgent } from '@ducks/app';
import { logErrorAsync } from '@ducks/error';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  componentDidCatch(error, errorInfo) {
    const { logError, agent } = this.props;

    logError({
      error: JSON.stringify({
        message: error.message,
        stack: errorInfo.componentStack
      }),
      client: JSON.stringify(agent)
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (fallback && hasError) {
      return fallback;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  logError: PropTypes.func,
  agent: PropTypes.shape({})
};

export default connect(
  state => ({
    agent: selectAgent(state)
  }),
  dispatch => ({
    logError: error => {
      dispatch(logErrorAsync(error, 'account'));
    }
  })
)(ErrorBoundary);
