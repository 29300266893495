import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

const xhr = new XHR(null, {
  loadPath: '/locales/{{lng}}/{{ns}}.json'
});

/**
 * List of locales:
 * @see https://www.unicode.org/cldr/charts/32/summary/root.html
 */
const fallbackLng = ['en-GB'];
const availableLanguages = ['en-GB'];

i18n
  .use(xhr)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultLanguage: 'en-GB',
    fallbackLng,
    whitelist: availableLanguages,
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
