import colors from '../colors';

export default {
  underline: {
    '&::before': {
      borderBottom: `1px solid ${colors.borderColor}`
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${colors.borderColor}`
    }
  }
};
