import React from 'react';
import { Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import AppConfigFetcher from '@components/AppConfigFetcher';
import ErrorBoundary from '@components/ErrorBoundary';
import ErrorNotifier from '@components/ErrorNotifier';
import ModalProvider from '@components/ModalProvider';
import PageLoader from '@components/PageLoader';
import ScrollToTop from '@components/ScrollToTop';
import UserAutoAuthenticator from '@components/UserAutoAuthenticator';
import theme from '@theme';
import { useEmailVerify, useWebsocketConnection } from '@views/hooks';
import BaseLayout from '@views/layouts/BaseLayout';

import routes from './default';

const useGlobalStyles = makeStyles({
  '@global': {
    body: {
      height: '100%',
      overflowY: 'scroll'
    },
    html: {
      height: '100%'
    },
    '#root': {
      height: '100%'
    }
  }
});

function WebSocketConnection() {
  useWebsocketConnection();
  return null;
}

function EmailVerify() {
  useEmailVerify();
  return null;
}

export default function App() {
  useGlobalStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <React.Suspense fallback={<PageLoader />}>
          <ModalProvider>
            <UserAutoAuthenticator>
              <AppConfigFetcher>
                <BaseLayout>
                  <Switch>{routes}</Switch>
                  <EmailVerify />
                  <WebSocketConnection />
                </BaseLayout>
              </AppConfigFetcher>
            </UserAutoAuthenticator>
            <ScrollToTop />
            <ErrorNotifier />
          </ModalProvider>
        </React.Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
