import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import RouterLink from '@components/RouterLink';
import { getPath } from '@views/router-paths';

import StandardDialog from './StandardDialog';

const RecognitionsLeftIcon = props => (
  <SvgIcon width="142" height="128" viewBox="0 0 142 128" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#8AB2B7"
        fillRule="nonzero"
        d="M128 64c0-4.148-.395-8.247-1.173-12.257C121.035 21.885 94.778 0 64 0 28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 9.201 0 18.132-1.944 26.337-5.653a2 2 0 0 0-1.648-3.645C80.999 122.178 72.629 124 64 124 30.863 124 4 97.137 4 64S30.863 4 64 4c28.854 0 53.471 20.518 58.9 48.504.73 3.76 1.1 7.604 1.1 11.496a2 2 0 0 0 4 0z"
      />
      <path
        fill="#8AB2B7"
        fillRule="nonzero"
        d="M116.955 49.091C110.343 25.561 88.803 9 64 9 33.624 9 9 33.624 9 64s24.624 55 55 55c4.499 0 8.929-.54 13.22-1.6a2 2 0 0 0-.958-3.883A51.121 51.121 0 0 1 64 115c-28.167 0-51-22.833-51-51s22.833-51 51-51c22.999 0 42.974 15.357 49.104 37.173a2 2 0 0 0 3.85-1.082z"
      />
      <path
        fill="#8AB2B7"
        fillRule="nonzero"
        d="M23.61 64.509l.22.364c.175.284.38.611.616.978a88.162 88.162 0 0 0 2.335 3.45c2.53 3.56 5.404 7.12 8.577 10.45C44.558 89.408 54.37 95.25 64.5 95.25c4.49 0 8.977-1.177 13.426-3.38a2 2 0 0 0-1.776-3.585c-3.925 1.944-7.815 2.965-11.65 2.965-8.797 0-17.728-5.317-26.247-14.258-3.03-3.18-5.786-6.594-8.211-10.008a84.18 84.18 0 0 1-2.23-3.292 54.626 54.626 0 0 1-.756-1.214 2 2 0 1 0-3.446 2.03zm76.36-9.67C89.411 41.46 77.344 32.5 64.5 32.5c-10.115 0-19.925 5.69-29.124 15.098-3.174 3.246-6.05 6.716-8.58 10.187a85.86 85.86 0 0 0-2.338 3.363 56.951 56.951 0 0 0-.836 1.31 2 2 0 1 0 3.423 2.07l.183-.295c.16-.254.35-.55.572-.886a81.879 81.879 0 0 1 2.227-3.206c2.425-3.324 5.18-6.65 8.208-9.746C46.755 41.682 55.69 36.5 64.5 36.5c11.263 0 22.468 8.321 32.33 20.817a2 2 0 0 0 3.14-2.478zM64.5 75.083c6.19 0 11.208-5.018 11.208-11.208S70.69 52.667 64.5 52.667s-11.208 5.018-11.208 11.208S58.31 75.083 64.5 75.083zm0 4c-8.399 0-15.208-6.81-15.208-15.208 0-8.399 6.81-15.208 15.208-15.208 8.399 0 15.208 6.81 15.208 15.208 0 8.399-6.81 15.208-15.208 15.208z"
      />
      <circle cx="112.5" cy="93.5" r="29.5" fill="#FFADAD" />
      <circle
        cx="112.5"
        cy="93.5"
        r="25.17"
        fill="#FFADAD"
        stroke="#FFF"
        strokeWidth="2"
      />
      <text
        fill="#FFF"
        fontFamily="GothamPro-Bold, Gotham Pro"
        fontSize="21"
        fontWeight="bold"
        letterSpacing="-.774"
      />
    </g>
  </SvgIcon>
);

const RecognitionsArchivedIcon = props => (
  <SvgIcon width="185" height="150" viewBox="0 -5 150 130" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <g fill="currentColor">
        <path
          fillRule="nonzero"
          d="M28.139 40.978c0-.923.703-1.666 1.574-1.666h59.574c.871 0 1.574.74 1.574 1.653v1.55c0 .914.703 1.655 1.57 1.655.866 0 1.569-.741 1.569-1.656v-1.55C94 38.22 91.89 36 89.287 36H29.713C27.107 36 25 38.228 25 40.978v2.366c0 .915.703 1.656 1.57 1.656.866 0 1.569-.741 1.569-1.656v-2.366z"
        />
        <path d="M117 116.42a1.58 1.58 0 0 1-1.581 1.58H1.58A1.58 1.58 0 0 1 0 116.42s.144-47.146.088-47.931c-.057-.785-.075-1.297.516-1.762l9.408-7.39c.279-.218.623-.337.977-.337h10.49a1.58 1.58 0 1 1 0 3.16h-9.885l-5.128 3.983 104.5.247-5.521-4.25c-.137-.106-6.62.02-6.62.02a1.58 1.58 0 1 1 0-3.16h7.186c.354 0 .698.119.977.338l9.408 7.39c.59.464.516.892.516 1.76L117 116.42zm-3.162-46.776l-.118 45.195H3.34l-.28-45.303 110.778.108zM1.581 0H115.42A1.57 1.57 0 0 1 117 1.558s-.144 15.316-.088 16.09c.057.773.075 1.278-.516 1.736l-9.408 7.283a1.596 1.596 0 0 1-.977.333h-8.382a1.57 1.57 0 0 1-1.581-1.558c0-.86.707-1.557 1.58-1.557h7.778l5.128-3.926-104.5-.243 5.521 4.19c.137.104 78.295-.021 78.295-.021a1.57 1.57 0 0 1 1.581 1.557A1.57 1.57 0 0 1 89.85 27H10.99c-.355 0-.7-.117-.978-.333L.604 19.384c-.59-.458-.516-.88-.516-1.737L0 1.557C0 .698.708 0 1.581 0zm1.581 16.509L3.28 3.115h110.38l.28 13.5L3.162 16.51z" />
        <path
          fillRule="nonzero"
          d="M43.856 95.755c.306.136.767.324 1.307.53 1.029.393 2.121.763 3.251 1.098C51.974 98.439 55.364 99 58.5 99s6.526-.56 10.086-1.617a45.348 45.348 0 0 0 3.251-1.098c.54-.206 1.001-.394 1.307-.53 1.13-.504 1.856-1.602 1.856-2.81V83.09C75 81.384 73.576 80 71.82 80H45.18C43.425 80 42 81.384 42 83.091v9.853c0 1.21.725 2.307 1.856 2.81zm1.325-2.81V83.09h26.638v9.853c-.247.11-.656.277-1.144.464-.953.363-1.967.707-3.018 1.019-3.276.972-6.36 1.482-9.157 1.482-2.797 0-5.88-.51-9.157-1.482a42.106 42.106 0 0 1-3.018-1.02 25.13 25.13 0 0 1-1.144-.463zM24.162 47.793c0-.888.709-1.604 1.586-1.604h69.504c.878 0 1.586.712 1.586 1.592v20.625c0 .88.708 1.594 1.58 1.594.874 0 1.582-.714 1.582-1.594V47.78C100 45.138 97.874 43 95.252 43H25.748C23.123 43 21 45.146 21 47.793v20.349c0 .88.708 1.594 1.581 1.594s1.581-.713 1.581-1.594V47.793z"
        />
      </g>
      <g transform="translate(92 73)">
        <circle cx="29.5" cy="29.5" r="29.5" fill="currentColor" />
        <circle
          cx="29.5"
          cy="29.5"
          r="25.17"
          fill="currentColor"
          stroke="#FFF"
          strokeWidth="2"
        />
        <path
          fill="#FFF"
          d="M29.675 37.82c.977 0 1.812.347 2.504 1.04.693.693 1.04 1.535 1.04 2.526 0 .977-.347 1.812-1.04 2.505a3.414 3.414 0 0 1-2.504 1.039 3.414 3.414 0 0 1-2.505-1.039 3.414 3.414 0 0 1-1.039-2.505c0-.977.343-1.815 1.028-2.515.686-.7 1.524-1.05 2.516-1.05zm.46-3.127h-.963c-.102-1.663-.438-3.675-1.006-6.037l-1.138-4.769C26.343 21.03 26 18.98 26 17.74c0-1.122.335-2.027 1.006-2.712S28.552 14 29.631 14c1.05 0 1.921.346 2.614 1.039.693.693 1.04 1.571 1.04 2.636 0 1.108-.365 3.179-1.095 6.212l-1.159 4.769c-.408 1.691-.707 3.704-.897 6.037z"
        />
      </g>
    </g>
  </SvgIcon>
);

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 64,
    color: theme.palette.error.main
  }
}));

export default function RecognitionsLeftDialog({
  onClose,
  count = 0,
  isLowLevel = true,
  ...props
}) {
  const classes = useStyles();

  return (
    <StandardDialog
      onClose={onClose}
      title={(() => {
        let icon = null;
        let text = null;

        if (count === 0) {
          icon = <RecognitionsArchivedIcon className={classes.icon} />;
          text = 'No recognitions';
        }

        if (isLowLevel) {
          icon = <RecognitionsLeftIcon className={classes.icon} />;
          text = `${count} recognitions left!`;
        }

        return (
          <>
            {icon}
            {text && (
              <Typography variant="h3" color={isLowLevel ? 'primary' : 'error'}>
                {text}
              </Typography>
            )}
          </>
        );
      })()}
      content={(() => {
        if (count === 0) {
          return (
            <>
              <Typography variant="subtitle1" gutterBottom>
                All your ROARs have been archived.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                None is able to scan them now.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Upgrade your plan to get more recognitions
              </Typography>
            </>
          );
        }

        if (isLowLevel) {
          return (
            <>
              <Typography variant="subtitle1" gutterBottom>
                You&apos;re almost out of free recognitions!
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Once the counter reaches zero, all your ROARs will be moved to
                Archive.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                No one will able to scan them until you have recognitions
                available
              </Typography>
            </>
          );
        }

        return null;
      })()}
      actions={
        <>
          <Button variant="outlined" onClick={onClose} color="primary">
            Got it
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={getPath('plans')}
            onClick={onClose}
          >
            Upgrade plan
          </Button>
        </>
      }
      {...props}
    />
  );
}

RecognitionsLeftDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  isLowLevel: PropTypes.bool.isRequired
};
