import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';

// 'api'|front'|'android'|'ios'|'account'|'editor'|'web'
const loggerEndpoint = new Endpoint(({ source = '' }) => `/logger/${source}`);

// eslint-disable-next-line import/prefer-default-export
export const logError = async (body, source) => {
  try {
    const response = loggerEndpoint.post(body, { uriParams: { source } });

    if (response.data) {
      return Promise.resolve();
    }

    return parseErrorResponseDataMessage(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
