import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

import {
  wsConnect,
  wsConnected,
  wsDisconnect,
  wsDisconnected,
  wsReceiveMessage
} from '@ducks/websocket';
import {
  AWSLAB_CHECK_INTERVAL,
  AWSLAB_LAST_REQUEST,
  AWSLAB_MAX_IDLE_DURATION
} from '@utilities';

import * as localStorage from '../services/local-storage-service';

class WebSocketConnection {
  constructor(url) {
    this.socket = null;
    this.subscription = null;
    this.client = null;
    this.url = url;

    /**
     * unsafe reconnecting flag for AWS LAB
     * @todo find another solution.
     */
    this.UNSAFE_reconnecting = false;
  }

  connect = (store, token) => {
    const socket = new SockJS(this.url);
    const client = new Client({
      connectHeaders: {
        'X-Authorization': `Bearer ${token}`
      },
      webSocketFactory: this.webSocketFactory(store, token),
      debug: process.env.NODE_ENV !== 'production' ? console.log : () => {}
    });

    // handlers
    socket.onerror = this.reconnect(store, token);
    client.onStompError = this.reconnect(store, token);
    client.onWebSocketClose = this.onDisconnect(store, token);
    client.onConnect = this.onConnect(store);
    client.activate();

    console.info('[ws]: connecting...');

    this.UNSAFE_reconnecting = false;
    this.socket = socket;
    this.client = client;
  };

  disconnect = () => {
    console.info('[ws]: disconnect');

    if (this.subscription !== null) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    if (this.client !== null) {
      this.client.deactivate();
      this.client = null;
    }

    if (this.socket !== null) {
      this.socket.close();
      this.socket = null;
    }
  };

  reconnect = (store, token) => () => {
    setTimeout(() => {
      console.info('[ws]: reconnect');
      this.disconnect();
      this.connect(store, token);
    }, 5000);
  };

  // During the specified period of time, `AWS` LAB is disconnecting,
  // so we manually restart the connection.
  // eslint-disable-next-line camelcase
  UNSAFE_reconnectOnIdleHandler = (store, token) => {
    let intervalId;

    return () => {
      intervalId = setInterval(() => {
        localStorage.getItem(AWSLAB_LAST_REQUEST, (err, value) => {
          const lastRequest = new Date(value);
          const duration = new Date() - lastRequest;

          if (duration > AWSLAB_MAX_IDLE_DURATION) {
            this.reconnect(store, token)();
            clearInterval(intervalId);
          }
        });
      }, AWSLAB_CHECK_INTERVAL);
    };
  };

  onConnect = (store, token) => () => {
    console.info('[ws]: connected');

    localStorage.setItem(AWSLAB_LAST_REQUEST, new Date());
    this.UNSAFE_reconnectOnIdleHandler(store, token)();

    const { user } = store.getState();
    store.dispatch(wsConnected());

    if (user.id && this.client.connected) {
      this.subscription = this.client.subscribe(
        `/queue/progress/${user.id}`,
        message => {
          if (message.command === 'MESSAGE') {
            const body = JSON.parse(message.body) || {};
            store.dispatch(wsReceiveMessage(body));
          }
        }
      );
    }
  };

  onDisconnect = (store, token) => event => {
    console.info('[ws]: disconnected');
    store.dispatch(wsDisconnected());

    if (event && event.code === 1006) {
      this.reconnect(store, token)();
    }
  };

  webSocketFactory = (store, token) => () => {
    if (this.UNSAFE_reconnecting) {
      this.reconnect(store, token)();
    }

    this.UNSAFE_reconnecting = true;
    return this.socket;
  };
}

const createWebSocketMiddleware = url => {
  const ws = new WebSocketConnection(url);

  return store => next => action => {
    if (wsConnect.match(action)) {
      const { token } = action.payload;
      ws.connect(store, token);
    }

    if (wsDisconnect.match(action)) {
      ws.disconnect();
    }

    return next(action);
  };
};

export default createWebSocketMiddleware;
