import colors from '../colors';

export default () => ({
  root: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1
    },
    '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.borderColor
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.borderColor
    }
  },
  input: {
    // padding: theme.spacing(3, 4)
  },
  notchedOutline: {
    borderColor: colors.borderColor
  }
});
