import { createReducer } from '@reduxjs/toolkit';

import { deleteTeam, inviteUserToTeam, loadTeam, updateTeam, getMyInfoOnTeam } from './actions';

/**
 * @todo create reducers for team actions.
 */

export default createReducer(
  {
    team: {}
  },
  {
    [loadTeam.success]: (state, action) => {
      state.team = action.payload.data;
    },
    [updateTeam.success]: (state, action) => {
      state.team.team.name = action.payload.name;
    },
    [deleteTeam.success]: state => {
      state.team = {};
    },
    [inviteUserToTeam.success]: (state, action) => {
      state.team.roster.push(action.payload.data);
    },
    [getMyInfoOnTeam.success]: (state, action) => {
      state.team.me = action.payload.data;
    }
  }
);
