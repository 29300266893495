import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PropTypes from 'prop-types';

import Loader from '@components/Loader';
import StandardDialog from '@components/StandardDialog';
import { selectPendingState } from '@ducks/pending';
import {
  resendConfirmationEmail,
  resendConfirmationEmailAsync,
  selectCurrentUser
} from '@ducks/user';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 64
  },
  content: {
    padding: theme.spacing(6)
  },
  button: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  }
}));

export default function VerifyEmailDialog({ onClose, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSent, setSent] = React.useState(false);
  const user = useSelector(selectCurrentUser, shallowEqual);
  const isPending = useSelector(state =>
    selectPendingState(state, [resendConfirmationEmail.actionName])
  );

  const handleClick = () => {
    dispatch(resendConfirmationEmailAsync())
      .then(setSent)
      .catch(console.error);
  };

  return (
    <StandardDialog
      onClose={onClose}
      title={
        isSent ? (
          <>
            <Typography variant="h3" color="primary" gutterBottom>
              Thank you
            </Typography>
            <Typography variant="h3" color="primary">
              Request has been sent
            </Typography>
          </>
        ) : (
          <>
            <MailOutlineIcon
              className={classes.icon}
              color="primary"
              fontSize="large"
            />
            <Typography variant="h3" color="primary">
              Verify your email
            </Typography>
          </>
        )
      }
      content={
        <>
          {!isSent && (
            <div className={classes.content}>
              <Typography variant="subtitle1" gutterBottom>
                We sent a verification email to {user.email}
              </Typography>
              <Typography variant="subtitle2">
                Click the link in the email to get started!
              </Typography>
            </div>
          )}
          <Divider />
          {isPending && <Loader />}
          {!isSent && !isPending && (
            <Button
              className={classes.button}
              color="primary"
              onClick={handleClick}
            >
              Email didn&apos;t arrive or want to use a different email?
            </Button>
          )}
          {isSent && (
            <Button
              className={classes.button}
              onClick={onClose}
              variant="contained"
            >
              Close
            </Button>
          )}
        </>
      }
      {...props}
    />
  );
}

VerifyEmailDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};
