import colors from '../colors';
import shape from '../shape';

export default {
  root: {},
  select: {
    borderRadius: shape.borderRadius
  },
  selectIcon: {
    top: 'calc(50% - 10px)'
  },
  selectRoot: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: shape.borderRadius
  }
};
