import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import {
  bindHover,
  bindMenu,
  usePopupState
} from 'material-ui-popup-state/hooks';
import Menu from 'material-ui-popup-state/HoverMenu';
import PropTypes from 'prop-types';

import TopbarLinkButton from './TopbarLinkButton';

export const ParentPopupState = React.createContext(null);

const useStyles = makeStyles({
  root: {}
});

const TopbarDropdownMenu = React.forwardRef(function DropdownMenu(
  { title, titleProps, className, popupId, variant, children, ...props },
  ref
) {
  const classes = useStyles();
  const popupState = usePopupState({ popupId, variant });

  return (
    <div ref={ref} className={clsx(classes.root, className)}>
      <TopbarLinkButton
        variant="text"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        to="#"
        {...titleProps}
        {...bindHover(popupState)}
      >
        {title}
      </TopbarLinkButton>
      <ParentPopupState.Provider value={popupState}>
        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          {...props}
        >
          {children}
        </Menu>
      </ParentPopupState.Provider>
    </div>
  );
});

TopbarDropdownMenu.propTypes = {
  title: PropTypes.string.isRequired,
  titleProps: PropTypes.shape({}),
  popupId: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['popover', 'popper']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

TopbarDropdownMenu.defaultProps = {
  titleProps: {},
  className: '',
  variant: 'popover'
};

export default TopbarDropdownMenu;
