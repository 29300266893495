import { useCallback, useState } from 'react';

export default function usePagination(
  initialPaginationState = { limit: 10, offset: 0, page: 1 }
) {
  const [pagination, setPagination] = useState(initialPaginationState);

  const updatePagination = useCallback((event, offset, page) => {
    window.scrollTo(0, 0);
    setPagination(prevState => ({ ...prevState, offset, page }));
  }, []);

  const resetPagination = useCallback(() => {
    setPagination(initialPaginationState);
  }, [initialPaginationState]);

  return [pagination, updatePagination, resetPagination];
}
