import colors from '../colors';
import palette from '../palette';
import shape from '../shape';

export default theme => ({
  main: {
    alignItems: 'center',
    paddingLeft: theme.spacing(1.5),
    border: `1px solid ${colors.borderColor}`,
    borderRadius: shape.borderRadius
  },
  searchIcon: {
    marginTop: 0,
    color: palette.primary.main
  },
  searchText: {
    flex: '1 0',
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none'
    }
  }
});
