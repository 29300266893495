import { parseError } from '@/utilities';
import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';

const baseURL = 'user';
const signInEndpoint = new Endpoint(`${baseURL}/login`);
const signInPriceEndpoint = new Endpoint(`${baseURL}/emailExists`);
const confirmEmailEndpoint = new Endpoint(
  ({ token = '' }) => `${baseURL}/confirm/${token}`
);
const declineEmailEndpoint = new Endpoint(
  ({ token = '' }) => `${baseURL}/decline/${token}`
);
const resendConfirmationEmailEndpoint = new Endpoint(
  `${baseURL}/resendConfirmationEmail`
);
const signOutEndpoint = new Endpoint(`${baseURL}/logout`);
const signUpEndpoint = new Endpoint(`${baseURL}/signUp`);
const facebookAuthEndpoint = new Endpoint(`${baseURL}/facebook/connect`);
const currentUserEndpoint = new Endpoint(`${baseURL}/me`);
const deleteCurrentUserEndpoint = new Endpoint(`${baseURL}`);
const receiveReceiptsEndpoint = new Endpoint(
  ({ value = false }) => `${baseURL}/me/setReceiveReceipts/${value}`
);
const recoveryPasswordRequestEndpoint = new Endpoint(
  `${baseURL}/recoveryPasswordRequest`
);
const recoveryPasswordConfirmEndpoint = new Endpoint(
  `${baseURL}/recoveryPassword`
);
const updateCurrentUserPasswordEndpoint = new Endpoint(
  `${baseURL}/updatePassword`
);

export const signIn = async ({ username, password }) => {
  try {
    const response = await signInEndpoint.post({
      username,
      password
    });

    if (response.data && response.data.token) {
      return response.data.token;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const emailCheck = async ({ username }) => {
  try {
    let endpoint = new Endpoint(`${baseURL}/emailExists?email=${username}`);
    const response = await endpoint.get();
    if (response.data) {
      return response.data;
    }
    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const confirmEmail = async token => {
  try {
    const response = await confirmEmailEndpoint.get({ uriParams: { token } });

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const declineEmail = async token => {
  try {
    const response = await declineEmailEndpoint.get({ uriParams: { token } });
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const resendConfirmationEmail = async () => {
  try {
    const response = await resendConfirmationEmailEndpoint.post();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const signUp = async ({
  email,
  firstName,
  lastName,
  password,
  isShowTutorial
}) => {
  try {
    const response = await signUpEndpoint.post({
      email,
      firstName,
      lastName,
      password,
      isShowTutorial
    });

    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const facebookAuth = async ({ token }) => {
  try {
    const response = await facebookAuthEndpoint.post({ token });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await currentUserEndpoint.get();

    if (response.data && response.data.success && response.data.user) {
      return response.data.user;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateCurrentUser = async params => {
  try {
    const response = await currentUserEndpoint.put(params);

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteCurrentUser = async reason => {
  try {
    const response = await deleteCurrentUserEndpoint.delete({
      data: { message: reason }
    });

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateCurrentUserPassword = async dto => {
  try {
    const response = await updateCurrentUserPasswordEndpoint.post(dto);

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const signOut = async () => {
  try {
    const response = await signOutEndpoint.get();
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const recoveryPasswordRequest = async email => {
  try {
    const response = await recoveryPasswordRequestEndpoint.get({
      params: { email }
    });

    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const recoveryPasswordConfirm = async (password, token) => {
  try {
    const response = await recoveryPasswordConfirmEndpoint.post({
      password,
      token
    });

    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const setReceiveReceipts = async value => {
  try {
    const response = await receiveReceiptsEndpoint.put(
      {},
      { uriParams: { value } }
    );

    if (response.data && response.data.success) {
      // return requested value
      return value;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getInvoices = async (from, to) => {
  try {
    let endpoint = new Endpoint(`invoice/find?from=${from}&to=${to}`);
    const response = await endpoint.get();
    if (response.data) {
      return response.data;
    }
    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadInvoice = async transactionId => {
  try {
    let endpoint = new Endpoint(`invoice/download/${transactionId}`);
    const response = await endpoint.get();
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
