import { darken, fade } from '@material-ui/core/styles/colorManipulator';

import colors from '../colors';
import palette from '../palette';

export default theme => ({
  root: {
    padding: theme.spacing(2, 5.5),
    fontWeight: 600,
    textTransform: 'none',
    color: theme.palette.text.secondary,

    '&.Mui-disabled': {
      opacity: 0.5
    }
  },
  contained: {
    color: palette.text.primary,
    boxShadow: 'none',
    border: `1px solid ${colors.borderColor}`,

    '&:hover': {
      backgroundColor: darken(palette.grey[300], 0.045),
      boxShadow: 'none'
    },
    '&:active': {
      backgroundColor: darken(palette.grey[300], 0.14),
      boxShadow: 'none'
    }
  },
  containedPrimary: {
    border: 'none',
    '&:active': {
      backgroundColor: colors.darkPrimaryColor, // @todo use darken()
      // backgroundColor: darken(palette.primary.main, 0.38),
      boxShadow: 'none'
    },

    '&.Mui-disabled': {
      color: colors.white,
      backgroundColor: palette.primary.main
    }
  },
  containedSizeSmall: {
    padding: theme.spacing(1.5, 5)
  },
  containedSizeLarge: {
    padding: theme.spacing(2.25, 6)
  },
  outlined: {
    padding: theme.spacing(1.75, 5.25)
  },
  outlinedPrimary: {
    borderColor: palette.primary.main,
    '&:hover': {
      borderWidth: 1
    },
    '&:active': {
      color: colors.white,
      background: fade(palette.primary.main, 0.7),
      '& .MuiSvgIcon-root': {
        fill: colors.white
      }
    },
    '&.Mui-disabled': {
      color: palette.primary.main,
      borderColor: palette.primary.main,
      borderWidth: 1
    }
  },
  outlinedSizeSmall: {
    padding: theme.spacing(1.25, 4.75)
  },
  outlinedSizeLarge: {
    padding: theme.spacing(2, 5.75),
    lineHeight: 1.2
  },
  text: {
    padding: theme.spacing(2)
  },
  textSizeSmall: {
    padding: theme.spacing(1.5)
  },
  textSizeLarge: {
    padding: theme.spacing(2.5)
  }
});
