export const SUBSCRIPTION_PLAN_TYPE = 'SUBSCRIPTION';
export const FREE_PLAN_TYPE = 'FREE';
export const TRIAL_PLAN_TYPE = 'TRIAL';
export const ESPECIAL_PLAN_TYPE = 'ESPECIAL';
export const YEARLY_PREPAID_MONTHLY_SUBS_PLAN_TYPE =
  'YEARLY_PREPAID_MONTHLY_SUBS';

export const CUSTOM_PLAN_IDENTIFIER = 'CUSTOM';
export const VIEW_ONLY_PLAN_IDENTIFIER = 'VIEW_ONLY';

export const MONTHLY_PERIOD = 'monthly';
export const QUARTERLY_PERIOD = 'quarterly';
export const ANNUAL_PERIOD = 'annually';

export const MONTHLY_PERIOD_ADDON = '1month';
export const QUARTERLY_PERIOD_ADDON = '3month';
export const ANNUAL_PERIOD_ADDON = '12month';

export const MONTHLY_FREQUENCY = 1;
export const QUARTERLY_FREQUENCY = 3;
export const ANNUAL_FREQUENCY = 12;

export const FREQUENCES = {
  [MONTHLY_FREQUENCY]: MONTHLY_PERIOD,
  [QUARTERLY_FREQUENCY]: QUARTERLY_PERIOD,
  [ANNUAL_FREQUENCY]: ANNUAL_PERIOD
};

export const PERCENTAGE_COUPON_TYPE = 'PERCENTAGE';
export const FIXED_AMOUNT_COUPON_TYPE = 'FIXED_AMOUNT';
