import palette from '../palette';

export default {
  root: {
    fontWeight: 'bold'
  },
  icon: {
    width: 16,
    height: 16,
    fill: palette.black,
    opacity: '1'
  }
};
