import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center'
  },
  fullWidth: {
    width: '100%'
  },
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '100%',
    backgroundColor: '#fff'
  },
  gutters: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5)
  }
}));

export default function Loader({
  disableGutters = false,
  disableBackground = true,
  fullWidth = true,
  ...props
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        { [classes.fullWidth]: fullWidth },
        { [classes.gutters]: !disableGutters },
        { [classes.background]: !disableBackground }
      )}
    >
      <CircularProgress {...props} />
    </div>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  disableGutters: PropTypes.bool,
  disableBackground: PropTypes.bool,
  fullWidth: PropTypes.bool
};
