import React, { createContext } from 'react';
import cuid from 'cuid';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

export const ModalContext = createContext({
  showModal: () => {},
  hideModal: () => {},
  destroyModal: () => {}
});

const initialState = {};

const ModalProvider = ({ children }) => {
  const [state, setState] = React.useState(initialState);

  const hideModal = React.useCallback(
    id =>
      setState(prevState => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          props: {
            ...prevState[id].props,
            open: false
          }
        }
      })),
    []
  );

  const destroyModal = React.useCallback(
    id => setState(prevState => omit(prevState, id)),
    []
  );

  const showModal = React.useCallback(
    (component, props = {}) => {
      const id = cuid();

      setState(prevState => ({
        ...prevState,
        [id]: {
          component,
          props: {
            ...props,
            open: true
          }
        }
      }));

      return { id, hide: () => hideModal(id), destroy: () => destroyModal(id) };
    },
    [destroyModal, hideModal]
  );

  return (
    <ModalContext.Provider
      value={{ hideModal, showModal, destroyModal, state }}
    >
      {keys(state).map(id => {
        const { component: Component, props } = state[id];

        return Component ? (
          <Component
            key={id}
            {...props}
            onClose={() => hideModal(id)}
            onExited={() => destroyModal(id)}
          />
        ) : null;
      })}
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ModalProvider;
