import axios from 'axios';
import EndpointFactory from 'axios-endpoints';
import has from 'lodash/has';

import { ApiError, getJwtToken } from './helpers';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BACKEND
});

const handleRequestError = error => {
  return Promise.reject(error);
};

const handleResponseError = error => {
  if (has(error, 'response.data')) {
    const {
      message,
      code,
      timestamp,
      status,
      error: type,
      path
    } = error.response.data;

    return Promise.reject(
      new ApiError(message, code, timestamp, path, status, type)
    );
  }

  return Promise.reject(error);
};

const handleRequest = async config => {
  const token = getJwtToken();

  if (token) {
    config.headers['X-Authorization'] = `Bearer ${token}`;
  }

  return config;
};

client.interceptors.request.use(handleRequest, handleRequestError);
client.interceptors.response.use(response => response, handleResponseError);

export const EndPointBack = EndpointFactory(client);

export default client;
