import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import {
  ANNUAL_FREQUENCY,
  ANNUAL_PERIOD,
  CUSTOM_PLAN_IDENTIFIER,
  FIXED_AMOUNT_COUPON_TYPE,
  FREE_PLAN_TYPE,
  MONTHLY_FREQUENCY,
  MONTHLY_PERIOD,
  PERCENTAGE_COUPON_TYPE,
  QUARTERLY_FREQUENCY,
  QUARTERLY_PERIOD,
  TRIAL_PLAN_TYPE
} from './constants';

export function getFrequencyByPeriod(period = MONTHLY_PERIOD) {
  switch (period) {
    case MONTHLY_PERIOD:
      return MONTHLY_FREQUENCY;
    case QUARTERLY_PERIOD:
      return QUARTERLY_FREQUENCY;
    case ANNUAL_PERIOD:
      return ANNUAL_FREQUENCY;
    default:
      return MONTHLY_FREQUENCY;
  }
}

export function getPlanDescriptionList(plan) {
  if (!plan) {
    return [];
  }

  /**
   * @todo
   * - translations
   */
  const list = [
    'Access to our augmented reality editor',
    'Access to our content management system to manage ar experiences',
    'Hosting / storage of your ar experiences',
    'Access to the dashboard where you can see your stats and manage projects',
    'Product maintenance and access to releases'
  ];

  if (plan.identifier === CUSTOM_PLAN_IDENTIFIER) {
    list.unshift('Extended support and analytics');
  }

  if (plan.type !== FREE_PLAN_TYPE) {
    list.push('Customer support');
  }

  return list;
}

export function calculatePriceWithCoupon(value = 0, coupon = {}) {
  let price = value;

  if (coupon && coupon.type && isNumber(coupon.value)) {
    if (coupon.type === FIXED_AMOUNT_COUPON_TYPE) {
      price = value - coupon.value;
    }

    if (coupon.type === PERCENTAGE_COUPON_TYPE) {
      price = Math.round((value * (100 - coupon.value)) / 100);
    }
  }

  return +price.toFixed(0);
}

/**
 * It may be unsafe.
 * @todo make new comparison method
 */
export function isFuturePlan(plan = {}, futurePlan = {}) {
  if (!futurePlan || !plan) {
    return false;
  }

  return (
    plan.recognitionAmount === futurePlan.recognitionAmount &&
    plan.roarAmount === futurePlan.roarAmount
  );
}

/**
 * It may be unsafe.
 * @todo make new comparison method
 */
export function checkPlanSubscription(plan = {}) {
  if (!plan) {
    return false;
  }

  return plan.planType !== FREE_PLAN_TYPE && plan.planType !== TRIAL_PLAN_TYPE;
}

/**
 * It may be unsafe.
 * @todo make new comparison method
 */
export function isCurrentPlan(plan = {}, activePlan = {}, activePlanDetail = {}) {
  if (!activePlan || !plan) {
    return false;
  }
  
  return (
    (plan.id === activePlan.planId || activePlanDetail.identifier === plan.identifier) && activePlan.price > 0
  );
}

export function getFuturePlanTargetId(futurePlanInfo = {}) {
  if (!futurePlanInfo || isEmpty(futurePlanInfo)) {
    return undefined;
  }

  return futurePlanInfo.targetPlanId || futurePlanInfo.targetId;
}

export function extractPlanTitle(plan) {
  if (plan.price !== 0) {
    return `Plan with ${plan.recognitionAmount} recognition and ${plan.roarAmount} targets for ${plan.price}`;
  }

  return 'Free plan';
}
