// import colors from '../colors';
// import shape from '../shape';

export default {
  // root: {
  //   background: colors.white
  // },
  // select: {
  //   '&:focus': {
  //     backgroundColor: colors.white,
  //     borderRadius: shape.borderRadius
  //   }
  // },
  // icon: {
  //   top: 'calc(50% - 10px)',
  //   color: colors.darkPrimaryColor
  // }
};
