import { parseError } from '@utilities';

import * as actions from './actions';

export const loadTeamAsync = () => async (dispatch, getStore, { api }) => {
  dispatch(actions.loadTeam.request());

  try {
    const data = await api.team.loadTeam();
    dispatch(actions.loadTeam.success(data));
    return data;
  } catch (e) {
    dispatch(actions.loadTeam.failure(parseError(e)));
  }
};

export const getMyInfoOnTeamAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getMyInfoOnTeam.request());

  try {
    const data = await api.team.getMyInfoOnTeam();
    dispatch(actions.getMyInfoOnTeam.success(data));
    return data;
  } catch (e) {
    // dispatch(actions.getMyInfoOnTeam.failure(parseError(e)));
  }
};

export const createTeamAsync = (teamName, members) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.createTeam.request());

  const invites = [];
  members.map(member => {
    invites.push({
      email: member.email,
      firstName: member.email.split('@')[0],
      role: member.role.value
    });
    return member;
  });
  const team = {
    name: teamName,
    invites
  };
  try {
    var data = await api.team.createTeam(team);
    await dispatch(loadTeamAsync());
    return data;
  } catch (e) {
    dispatch(actions.createTeam.failure(parseError(e)));
  }
};

export const updateTeamAsync = teamName => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.updateTeam.request());

  try {
    const data = await api.team.renameTeam(teamName);
    data.name = teamName;
    dispatch(actions.updateTeam.success(data));
  } catch (e) {
    dispatch(actions.updateTeam.failure(parseError(e)));
  }
};

export const deleteTeamAsync = teamId => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deleteTeam.request());

  try {
    const data = await api.team.deleteTeam(teamId);
    dispatch(actions.deleteTeam.success(data));
  } catch (e) {
    dispatch(actions.deleteTeam.failure(parseError(e)));
  }
};

export const loadTeamMembersAsync = (team, params) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.loadTeamMembers.request());

  try {
    const data = await api.team.loadTeamMembers(team, params);
    dispatch(actions.loadTeamMembers.success(data));
  } catch (e) {
    dispatch(actions.loadTeamMembers.failure(parseError(e)));
  }
};

export const inviteUserToTeamAsync = user => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.inviteUserToTeam.request());

  try {
    const data = await api.team.inviteUserToTeam(user);
    return data;
  } catch (e) {
    dispatch(actions.inviteUserToTeam.failure(parseError(e)));
  }
};

export const updateTeamMemberAsync = (userId, role) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.updateTeamMember.request());

  try {
    const user = {
      id: userId,
      role
    };
    const data = await api.team.updateTeamMember(user);
    dispatch(actions.updateTeamMember.success(data));
    return data;
  } catch (e) {
    dispatch(actions.updateTeamMember.failure(parseError(e)));
  }
};

export const deleteTeamMemberAsync = user => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deleteTeamMember.request());

  try {
    const data = await api.team.deleteTeamMember(user);
    dispatch(actions.deleteTeamMember.success(data));

    return data;
  } catch (e) {
    dispatch(actions.deleteTeamMember.failure(parseError(e)));
  }
};

export const resendInviteEmailAsync = user => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.resendInviteEmail.request());
  try {
    const data = await api.team.resendInviteEmail(user);
    dispatch(actions.resendInviteEmail.success(data));

    return data;
  } catch (e) {
    dispatch(actions.resendInviteEmail.failure(parseError(e)));
  }
};

export const resetPasswordAsync = user => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.resetPassword.request());

  try {
    const data = await api.team.resetPassword(user);
    dispatch(actions.resetPassword.success(data));

    return data;
  } catch (e) {
    dispatch(actions.resetPassword.failure(parseError(e)));
  }
};

export const deleteBulkMemberAsync = users => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deleteBulkMember.request());

  try {
    const data = await api.team.deleteBulkMember(users);
    dispatch(actions.deleteBulkMember.success(true));

    return data;
  } catch (e) {
    dispatch(actions.deleteBulkMember.failure(parseError(e)));
  }
};

export const approveTeamMemberAsync = user => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.approveTeamMember.request());

  try {
    const data = await api.team.approveTeamMember(user);
    dispatch(actions.deleteTeamMember.success(data));
  } catch (e) {
    dispatch(actions.deleteTeamMember.failure(parseError(e)));
  }
};
