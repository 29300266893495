import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import map from 'lodash/map';

import PrivateRoute from '@components/PrivateRoute';

import { getPath } from '../router-paths';

const routes = [
  {
    exact: true,
    path: getPath('home'),
    component: lazy(() => import('../pages/home')),
    isPrivate: true
  },
  {
    exact: true,
    path: getPath('dashboard'),
    component: lazy(() => import('../pages/dashboard')),
    isPrivate: true
  },
  {
    exact: true,
    path: getPath('plans'),
    component: lazy(() => import('../pages/plans'))
  },
  {
    exact: true,
    path: getPath('buy', ':planId', ':billingPeriod'),
    component: lazy(() => import('../pages/buy'))
  },
  {
    exact: true,
    path: getPath('addon', ':planId', ':billingPeriod'),
    component: lazy(() => import('../pages/addon'))
  },
  {
    exact: true,
    path: getPath('teams'),
    component: lazy(() => import('../pages/teams')),
    isPrivate: true
  },
  {
    path: getPath('profile'),
    component: lazy(() => import('../pages/profile')),
    isPrivate: true
  },
  {
    path: getPath('viewRoar', ':roarId'),
    component: lazy(() => import('../pages/viewRoar')),
    isPrivate: true
  },
  {
    exact: true,
    component: lazy(() => import('../pages/myExperiences')),
    path: getPath('myExperiences', ':workflowStateCode?'),
    isPrivate: true
  },
  {
    exact: true,
    component: lazy(() => import('../pages/myRoars')),
    path: getPath('myRoars', ':workflowStateCode?'),
    isPrivate: true
  },
  {
    exact: true,
    component: lazy(() => import('../pages/createRoar')),
    path: getPath('createRoar'),
    isPrivate: true
  },
  {
    component: lazy(() => import('../pages/verify')),
    path: getPath('verify', ':token')
  },
  {
    component: lazy(() => import('../pages/decline')),
    path: getPath('decline', ':token')
  },
  {
    component: lazy(() => import('../pages/forgotPassword')),
    path: getPath('forgotPassword')
  },
  {
    component: lazy(() => import('../pages/recoveryPassword')),
    path: getPath('recoveryPassword', ':token')
  },
  {
    component: lazy(() => import('../pages/signOut')),
    path: getPath('signOut')
  },
  {
    component: lazy(() => import('../pages/signUp')),
    path: getPath('signUp')
  },
  {
    component: lazy(() => import('../pages/signIn')),
    path: getPath('signIn')
  },
  {
    component: lazy(() => import('../pages/scanner')),
    path: getPath('scanner')
  },
  {
    component: lazy(() => import('../pages/campaign')),
    path: getPath('editCampaign', ':campaignId')
  },
  {
    component: lazy(() => import('../pages/campaign')),
    path: getPath('analyticCampaign', ':campaignId')
  },
  {
    component: lazy(() => import('../pages/campaign')),
    path: getPath('campaign')
  },
  {
    component: lazy(() => import('../pages/webar')),
    path: getPath('webar')
  },
  {
    component: lazy(() => import('../pages/webar')),
    path: getPath('editWebar', ':webarId')
  },
  {
    path: getPath('analytics', ':roarId'),
    component: lazy(() => import('../pages/analytics')),
    isPrivate: true
  },
  {
    component: lazy(() => import('../pages/noMatch'))
  }
];

/**
 * @todo add support for page loading fallback
 */
export default map(routes, ({ isPrivate, ...routeProps }, i) => {
  const RouteComponent = isPrivate ? PrivateRoute : Route;
  return <RouteComponent key={i} {...routeProps} />;
});
