// roar statuses
export const PUBLISHED_WORKFLOW_STATE_CODE = 'PUBLISHED';
export const ARCHIVED_WORKFLOW_STATE_CODE = 'ARCHIVED';
export const DRAFT_WORKFLOW_STATE_CODE = 'DRAFT';

// roar progress info types
export const ROAR_ALLOW_PUBLISH = 'ROAR_ALLOW_PUBLISH';

// roar types
export const TARGET_IMAGE_TYPE = 'TARGET_IMAGE';
export const ARKIT_ARCORE_TYPE = 'ARKIT_ARCORE';
export const FACEBOOK_TYPE = 'FB';
export const WEBAR_TYPE = 'WEBAR';

// error codes
export const BAD_MARKER_QUALITY_ERROR_CODE = 1;
export const NO_AR_CONTENT_ERROR_CODE = 2;

// ... rest
export const MARKER_SCAN_CONFIRMATION_TIMEOUT = 180000; // 3 min.
export const GOOD_MARKER_QUALITY = 2;
export const ANIMATION_TIMEOUT = 300;

export const OPENS = 'opens';
export const VIEWS = 'views';
export const VIEWSMONTH = 'viewsMonth';
export const VIEWSTODAY = 'viewsToday';
