import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {}
});

const TopbarDropdownMenuItem = React.forwardRef(function DropdownMenuItem(
  { children, className, ...props },
  ref
) {
  const classes = useStyles();

  return (
    <MenuItem ref={ref} className={clsx(className, classes.root)} {...props}>
      {children}
    </MenuItem>
  );
});

TopbarDropdownMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

TopbarDropdownMenuItem.defaultProps = {
  className: ''
};

export default TopbarDropdownMenuItem;
