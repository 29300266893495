import React from 'react';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import clsx from 'clsx';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  iconButton: {
    margin: theme.spacing(0, 2)
  },
  iconButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,

    '& .MuiSvgIcon-root': {
      fill: theme.palette.common.white
    }
  }
}));

const TopbarNotifications = React.forwardRef(function Notifications(
  { children },
  ref
) {
  const classes = useStyles();

  return (
    <PopupState variant="popover" popupId="notifications">
      {popupState => {
        const { isOpen } = popupState;

        return (
          <div ref={ref}>
            <IconButton
              color="primary"
              className={clsx(classes.iconButton, {
                [classes.iconButtonActive]: isOpen
              })}
              {...bindTrigger(popupState)}
            >
              <Badge
                color="error"
                variant="dot"
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Box p={2}>
                <Typography>{children}</Typography>
              </Box>
            </Popover>
          </div>
        );
      }}
    </PopupState>
  );
});

TopbarNotifications.propTypes = {
  children: PropTypes.node.isRequired
};

export default TopbarNotifications;
