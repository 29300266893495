import { createReducer } from '@reduxjs/toolkit';

import {
  deletePaymentMethod,
  getPaymentMethodInfo,
  getPaymentToken,
  setPaymentMethod
} from './actions';

const initialState = {
  token: '',
  info: {}
};

export default createReducer(initialState, {
  [getPaymentToken.success]: (state, action) => {
    state.token = action.payload;
  },
  [getPaymentToken.failure]: state => {
    state.token = initialState.token;
  },
  [getPaymentMethodInfo.success]: (state, action) => {
    state.info = action.payload;
  },
  [deletePaymentMethod.success]: state => {
    state.info = initialState.info;
    state.token = initialState.token;
  },
  [setPaymentMethod.success]: state => {
    state.token = initialState.token;
  }
});
