import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { resetErrorMessage, selectErrorMessage } from '@ducks/error';

export default function ErrorNotifier() {
  const dispatch = useDispatch();
  const errorMessage = useSelector(selectErrorMessage);

  /**
   * @todo force update if strings are equal
   */
  React.useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        onClose: () => dispatch(resetErrorMessage())
      });
    }
  }, [dispatch, errorMessage]);

  return null;
}
