import * as app from './app-api-service';
import * as billing from './billing-api-service';
import * as localStorage from './local-storage-service';
import * as logger from './logger-api-service';
import * as marker from './marker-api-service';
import * as payment from './payment-api-service';
import * as plan from './plan-api-service';
import * as roar from './roar-api-service';
import * as team from './team-api-service';
import * as toast from './toast-service';
import * as user from './user-api-service';
import * as addon from './addon-api-service';

export default {
  localStorage,
  toast,
  api: {
    app,
    addon,
    team,
    user,
    roar,
    marker,
    payment,
    plan,
    billing,
    logger
  }
};
