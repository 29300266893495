import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { deleteByPageCodeAsync } from '@ducks/roar';

export default function useDeleteRoar(cb) {
  const dispatch = useDispatch();

  return useCallback(
    async roar => {
      const pageCode = roar.pageCode || roar.roarCode;

      try {
        await dispatch(deleteByPageCodeAsync(pageCode));

        if (cb) {
          cb(null, roar);
        }
      } catch (e) {
        if (cb) {
          cb(e);
        }
      }
    },
    [cb, dispatch]
  );
}
