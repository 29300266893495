import palette from '../palette';

export default {
  root: {
    // fontSize: '0.875rem',
    '&.Mui-disabled': {
      background: palette.action.disabledBackground
    },
    '&:hover:not(.Mui-focused)': {
      color: palette.black
    }
  },
  input: {
    '&.Mui-disabled': {
      background: palette.inputDisabled
    }
  }
};
