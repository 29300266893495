import { lazy, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  ARKIT_ARCORE_TYPE,
  BAD_MARKER_QUALITY_ERROR_CODE,
  checkRoarBeforePublishAsync,
  FACEBOOK_TYPE,
  NO_AR_CONTENT_ERROR_CODE,
  normalizeRoar,
  TARGET_IMAGE_TYPE,
  WEBAR_TYPE
} from '@ducks/roar';
import withScanConfirmation from '@views/hocs/withScanConfirmation';

import * as toast from '../../services/toast-service';

import useModal from './useModal';

const TargetImagePreviewDialog = lazy(() =>
  import('../components/Roar/TargetImagePreviewDialog')
);
const ArKitArCorePreviewDialog = lazy(() =>
  import('../components/Roar/ArKitArCorePreviewDialog')
);
const BadMarkerQualityDialog = lazy(() =>
  import('../components/Roar/BadMarkerQualityDialog')
);
const FailedRoarVerifyDialog = lazy(() =>
  import('../components/Roar/FailedRoarVerifyDialog')
);
const ShareRoarDialog = lazy(() =>
  import('../components/Roar/ShareRoarDialog')
);
const NoArContentDialog = lazy(() =>
  import('../components/Roar/NoArContentDialog')
);

const EnhancedTargetImagePreviewDialog = withScanConfirmation(
  TargetImagePreviewDialog
);
const EnhancedArKitArCorePreviewDialog = withScanConfirmation(
  ArKitArCorePreviewDialog
);

export default function useCheckRoarBeforePublish(callback) {
  const dispatch = useDispatch();
  const { showModal } = useModal();

  const getFacebookDialogProps = useCallback(
    (normalizedRoar = {}) => ({
      image: normalizedRoar.markerImage,
      sharingLink: normalizedRoar.sharingLink,
      sharingText: 'Share to Facebook',
      socialSharingLink: `https://www.facebook.com/sharer/sharer.php?u=${normalizedRoar.sharingLink}`
    }),
    []
  );

  const getWebArDialogProps = useCallback(
    (normalizedRoar = {}) => ({
      image: normalizedRoar.markerImage,
      sharingLink: normalizedRoar.sharingLink,
      hideShare: true
    }),
    []
  );

  const handlePublish = useCallback(
    async roar => {
      let normalizedRoar = normalizeRoar(roar);

      try {
        if (normalizedRoar.allowPublish && callback) {
          callback(null, normalizedRoar);

          switch (normalizedRoar.arPlatform) {
            case FACEBOOK_TYPE:
              showModal(
                ShareRoarDialog,
                getFacebookDialogProps(normalizedRoar)
              );
              return;
            case WEBAR_TYPE:
              showModal(ShareRoarDialog, getWebArDialogProps(normalizedRoar));
              return;
            default:
              return;
          }
        }

        const newRoar = await dispatch(
          checkRoarBeforePublishAsync(normalizedRoar)
        );

        normalizedRoar = normalizeRoar(newRoar);

        let modal;

        const dialogProps = {
          image: normalizedRoar.markerImage,
          sharingLink: normalizedRoar.sharingLink,
          onConfirm: error => {
            if (!error && callback) {
              callback(null, normalizedRoar);

              if (modal && modal.hide) {
                modal.hide();
              }

              toast.success('Success');

              return;
            }

            if (modal) {
              modal.hide();
            }

            showModal(FailedRoarVerifyDialog, {
              pageCode: normalizedRoar.pageCode,
              onConfirm: () => {
                handlePublish(normalizedRoar);
              }
            });

            if (callback) {
              callback(error);
            }
          }
        };

        switch (normalizedRoar.arPlatform) {
          case TARGET_IMAGE_TYPE:
            modal = showModal(EnhancedTargetImagePreviewDialog, dialogProps);
            break;
          case ARKIT_ARCORE_TYPE:
            modal = showModal(EnhancedArKitArCorePreviewDialog, dialogProps);
            break;
          case FACEBOOK_TYPE:
            showModal(ShareRoarDialog, getFacebookDialogProps(normalizedRoar));
            break;
          case WEBAR_TYPE:
            showModal(ShareRoarDialog, getWebArDialogProps(normalizedRoar));
            break;
          default:
            break;
        }
      } catch (e) {
        if (e.code === BAD_MARKER_QUALITY_ERROR_CODE) {
          showModal(BadMarkerQualityDialog, {
            pageCode: normalizedRoar.pageCode
          });
        }

        if (e.code === NO_AR_CONTENT_ERROR_CODE) {
          showModal(NoArContentDialog, { pageCode: normalizedRoar.pageCode });
        }

        if (callback) {
          callback(e);
        }
      }
    },
    [callback, dispatch, getFacebookDialogProps, getWebArDialogProps, showModal]
  );

  return handlePublish;
}
