import each from 'lodash/each';

// eslint-disable-next-line import/prefer-default-export
export async function validateBraintreeHostedFields(fields = {}) {
  return new Promise((resolve, reject) => {
    let hasInvalidFields = false;

    each(fields, field => {
      if (!field.isValid) {
        hasInvalidFields = true;
        field.container.classList.add('is-invalid');
      } else {
        field.container.classList.remove('is-invalid');
      }
    });

    if (hasInvalidFields) {
      reject(new Error('Braintree has invalid fields'));
    } else {
      resolve(fields);
    }
  });
}
