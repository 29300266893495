import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';

const baseURL = 'team';
const approveURL = 'approve';

const myTeamEndpoint = new Endpoint(() => `${baseURL}/myTeam`);
const myInfoOnTeamEndpoint = new Endpoint(() => `${baseURL}/me`);
const renameEndpoint = new Endpoint(() => `${baseURL}/rename`);
const createEndpint = new Endpoint(() => `${baseURL}/create`);
const userEndpoint = new Endpoint(() => `${baseURL}/invite`);
const userApproveEndpoint = new Endpoint(
  ({ userId = '' }) => `${baseURL}/${approveURL}/${userId}`
);

export const loadTeam = async () => {
  try {
    const response = await myTeamEndpoint.get();
    if (response.data && response.data.success) {
      return response.data;
    }

    return {
      data: {}
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getMyInfoOnTeam = async () => {
  try {
    const response = await myInfoOnTeamEndpoint.get();
    if (response.data && response.data.success) {
      return response.data;
    }

    return {
      data: {}
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createTeam = async team => {
  try {
    const response = await createEndpint.post(team);
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const renameTeam = async teamName => {
  try {
    const response = await renameEndpoint.patch({ name: teamName });
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteMultiUser = async users => {
  try {
    let url = baseURL + '?';
    users.map(user => {
      url += `id=${user.userId}&`;
      return user;
    });
    const bulkDeleteEndpoint = new Endpoint(() => url);
    const response = await bulkDeleteEndpoint.delete();
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteTeam = async teamid => {
  try {
    const deleteEndpoint = new Endpoint(() => `${baseURL}/${teamid}`);
    const response = await deleteEndpoint.delete();
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const loadTeamMembers = async (team, params) => {
  try {
    const response = await userEndpoint.get({
      uriParams: { teamId: team.id },
      params
    });
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const inviteUserToTeam = async user => {
  try {
    const response = await userEndpoint.post(user);
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateTeamMember = async user => {
  try {
    const updateEndpoint = new Endpoint(() => `${baseURL}/role/update`);
    const response = await updateEndpoint.patch(user);
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteTeamMember = async user => {
  try {
    const deleteEndpoint = new Endpoint(() =>
      user.userId > 0
        ? `${baseURL}/delete/${user.userId}`
        : `${baseURL}/invite/delete/${user.email}`
    );

    const response = await deleteEndpoint.delete();
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const resendInviteEmail = async email => {
  try {
    const resendInviteEndpoint = new Endpoint(
      () => `${baseURL}/resend?email=${email}`
    );
    const response = await resendInviteEndpoint.get();
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const resetPassword = async email => {
  try {
    const resetPasswordEndpoint = new Endpoint(
      () => `/user/recoveryPasswordRequest?email=${email}`
    );
    const response = await resetPasswordEndpoint.get();
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteBulkMember = async users => {
  try {
    console.log(users);
    let url = `${baseURL}?`;
    users.map(user => {
      url += `id=${user.userId}&`;
      return user;
    });
    const bulkDeleteEndpoint = new Endpoint(() => url);
    const response = await bulkDeleteEndpoint.delete();
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const approveTeamMember = async user => {
  try {
    const response = await userApproveEndpoint.post(user, {
      uriParams: { userId: user.id }
    });
    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};
