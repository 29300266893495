import find from 'lodash/find';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import values from 'lodash/values';
import moment from 'moment';

import { CodeError, parseError } from '@utilities';

import * as actions from './actions';
import {
  BAD_MARKER_QUALITY_ERROR_CODE,
  GOOD_MARKER_QUALITY,
  NO_AR_CONTENT_ERROR_CODE,
  TARGET_IMAGE_TYPE
} from './constants';

export const filterAndSearchForOwnerAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.filterAndSearchForOwner.request(params));

  try {
    const data = await api.roar.filterAndSearchForOwner(params);
    dispatch(actions.filterAndSearchForOwner.success(data));
    return Promise.resolve(data);
  } catch (e) {
    dispatch(actions.filterAndSearchForOwner.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const filterAndSearchExperienceForOwnerAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.filterAndSearchExperienceForOwner.request(params));

  try {
    const data = await api.roar.filterAndSearchExperienceForOwner(params);
    dispatch(actions.filterAndSearchExperienceForOwner.success(data));
  } catch (e) {
    dispatch(actions.filterAndSearchExperienceForOwner.failure(parseError(e)));
  }
};

export const getByPageCodeAsync = pageCode => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getByPageCode.request(pageCode));

  try {
    const roar = await api.roar.getByPageCode(pageCode);
    dispatch(actions.getByPageCode.success(roar));
    return Promise.resolve(roar);
  } catch (e) {
    dispatch(actions.getByPageCode.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const getByIdAsync = roarId => async (dispatch, getStore, { api }) => {
  dispatch(actions.getById.request(roarId));

  try {
    const roarWithoutStatus = await api.roar.getById(roarId);
    /**
     * @todo
     * ==== remove this code if the roar will be a normalized model ====
     */
    const roarWithStatus = await api.roar.getByPageCode(
      roarWithoutStatus.roarCode
    );
    const roar = {
      ...roarWithoutStatus,
      workflowStateCode: roarWithStatus.status,
      allowPublish: roarWithStatus.allowPublish
    };
    /**
     * @todo
     * ==== remove this code if the roar will be a normalized model ====
     */
    dispatch(actions.getById.success(roar));
    return Promise.resolve(roar);
  } catch (e) {
    dispatch(actions.getById.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const getAnalyticsByIdAsync = (roarId, from = 0, to = 1900000000000) => async(dispatch, getStore, { api }) => {
  dispatch(actions.getAnalyticsById.request(roarId));
  try {
    if(from === 0) {
      from = moment().subtract(1, 'months').valueOf();
    }
    if(to === 1900000000000) {
      to = moment().valueOf();
    }
    const data = await api.roar.getAnalyticsById(roarId, from, to);
    for (var m = moment(from); m.diff(to, 'days') <= 0; m.add(1, 'days')) {
      if(!data.activity[moment(m).format('YYYY-MM-DD')]) {
        data.activity[moment(m).format('YYYY-MM-DD')] = 0;
      }
    }
    dispatch(actions.getAnalyticsById.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.getAnalyticsById.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const changeStatusByPageCodeAsync = (pageCode, status) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.changeStatusByPageCode.request());

  try {
    await api.roar.changeStatusByPageCode(pageCode, status);
    dispatch(actions.changeStatusByPageCode.success());
    return Promise.resolve();
  } catch (e) {
    dispatch(actions.changeStatusByPageCode.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const deleteByPageCodeAsync = (pageCode, status) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deleteByPageCode.request());

  try {
    await api.roar.deleteByPageCode(pageCode, status);
    dispatch(actions.deleteByPageCode.success());
    return Promise.resolve();
  } catch (e) {
    dispatch(actions.deleteByPageCode.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const deleteExperienceAsync = ({ roarId, experienceId }) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deleteExperience.request());

  try {
    await api.roar.deleteExperience({ roarId, experienceId });
    dispatch(actions.deleteExperience.success());
    return Promise.resolve();
  } catch (e) {
    dispatch(actions.deleteExperience.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const makeExperienceActiveAsync = ({ roarId, experienceId }) => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.makeExperienceActive.request());

  try {
    await api.roar.makeExperienceActive({ roarId, experienceId });
    dispatch(actions.makeExperienceActive.success());
    return Promise.resolve();
  } catch (e) {
    dispatch(actions.makeExperienceActive.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const getRoarForPreviewAsync = roarId => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getRoarForPreview.request(roarId));

  try {
    const roar = await api.roar.getById(roarId);
    dispatch(actions.getRoarForPreview.success(roar));
    return Promise.resolve(roar);
  } catch (e) {
    dispatch(actions.getRoarForPreview.failure(parseError(e)));
    return Promise.reject(e);
  }
};

export const checkRoarBeforePublishAsync = roar => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.checkRoarBeforePublish.request());

  try {
    /**
     * @todo
     * ==== remove this code if the roar will be a normalized model ====
     */
    const newRoar = await api.roar.getById(roar.id || roar.roarId);
    const { status, allowPublish } = await api.roar.getByPageCode(
      roar.roarCode || roar.pageCode
    );
    newRoar.workflowStateCode = status;
    newRoar.allowPublish = allowPublish;
    /**
     * @todo
     * ==== remove this code if the roar will be a normalized model ====
     */

    if (newRoar.arPlatform === TARGET_IMAGE_TYPE) {
      // [1] check marker
      if (!newRoar.marker) {
        throw new Error('You need add marker for this roar');
      }

      // [2] get rate
      const rate = await api.marker.getRate(newRoar);

      // [2.1] and check quality of rate
      if (rate < GOOD_MARKER_QUALITY) {
        throw new CodeError('', BAD_MARKER_QUALITY_ERROR_CODE);
      }
    }

    // [3] get active experience
    const activeExperience = find(newRoar.experiences, {
      isActive: true
    });

    // [4] check if ar content exist
    const isArContentExist =
      has(activeExperience, 'arContent') &&
      some(values(activeExperience.arContent), c => !isEmpty(c));

    if (!isArContentExist) {
      throw new CodeError('', NO_AR_CONTENT_ERROR_CODE);
    }

    // [6] done.
    dispatch(actions.checkRoarBeforePublish.success(newRoar));

    // [6.1] confirmation window will be shown below
    return Promise.resolve(newRoar);
  } catch (e) {
    dispatch(
      actions.checkRoarBeforePublish.failure({
        ...parseError(e),
        disableToast: e.code === BAD_MARKER_QUALITY_ERROR_CODE
      })
    );

    return Promise.reject(e);
  }
};

export const getRoarLinkOpeningCountAsync = roarIds => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getRoarLinkOpeningCount.request(roarIds));

  try {
    const data = await api.roar.getRoarLinkOpeningCount(roarIds);
    dispatch(actions.getRoarLinkOpeningCount.success(data));
  } catch (e) {
    dispatch(actions.getRoarLinkOpeningCount.failure(parseError(e)));
  }
};

export const getRoarScanCountAsync = roarIds => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getRoarScanCount.request(roarIds));

  try {
    const data = await api.roar.getRoarScanCount(roarIds);
    dispatch(actions.getRoarScanCount.success(data));
  } catch (e) {
    dispatch(actions.getRoarScanCount.failure(parseError(e)));
  }
};

export const getAllDemosAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getDemos.request());

  try {
    const data = await api.roar.getCampaignList();
    dispatch(actions.getDemos.success(data.data));
    return Promise.resolve(data.data);
  } catch(e) {
    dispatch(actions.getDemos.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const getAllWebARAsync = () => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getWebARs.request());

  try {
    const data = await api.roar.getWebARList();
    dispatch(actions.getWebARs.success(data.webArEntries));
    return Promise.resolve(data.webArEntries);
  } catch(e) {
    dispatch(actions.getWebARs.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const getDemoAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.getDemo.request(params));

  try {
    const data = await api.roar.getCampaignById(params);
    dispatch(actions.getDemo.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.getDemo.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const getWebARAsync = params => async (
  dispatch,
  getStore,
  { api  }
) => {
  dispatch(actions.getWebAR.request());
  
  try {
    const data = await api.roar.getWebARById(params);
    dispatch(actions.getWebAR.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.getWebAR.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const saveDemoAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.saveDemo.request(params));

  try {
    const data = await api.roar.addCampaign(params);
    dispatch(actions.saveDemo.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.saveDemo.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const saveWebARAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.saveWebAR.request(params));

  try {
    const data = await api.roar.addWebAR(params);
    dispatch(actions.saveWebAR.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.saveWebAR.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const uploadFileAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.uploadFile.request(params));

  try {
    const data = await api.roar.uploadFile(params);
    dispatch(actions.uploadFile.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.uploadFile.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const uploadWebARFileAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.uploadWebARFile.request(params));

  try {
    const data = await api.roar.uploadWebARFile(params);
    dispatch(actions.uploadWebARFile.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.uploadWebARFile.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const updateDemoAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.updateDemo.request(params));
  try {
    const data = await api.roar.updateCampaign(params);
    dispatch(actions.updateDemo.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.updateDemo.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const updateWebARAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.updateWebAR.request(params));

  try {
    const data = await api.roar.updateWebAR(params);
    dispatch(actions.updateWebAR.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.updateWebAR.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const deleteDemoAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deleteDemo.request(params));

  try {
    const data = await api.roar.deleteCampaign(params);
    dispatch(actions.deleteDemo.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.deleteDemo.failure(parseError(e)));
    return Promise.reject(e);  
  }
}

export const deleteWebARAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  dispatch(actions.deleteWebAR.request(params));

  try {
    const data = await api.roar.deleteWebAR(params);
    dispatch(actions.deleteWebAR.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.deleteWebAR.failure(parseError(e)));
    return Promise.reject(e);
  }
}

export const getAnalyticsAsync = params => async (
  dispatch,
  getStore,
  { api }
) => {
  if(params.from === null || params.from === 0) {
    params.from = moment().subtract(1, 'months').valueOf();
  }
  if(params.to && params.to === 19999999999999) {
    params.to = moment().valueOf();
  }
  dispatch(actions.getAnalyticDemo.request(params));

  try {
    const data = await api.roar.getAnalyticCampaign(params);

    for (var m = moment(params.from); m.diff(params.to, 'days') <= 0; m.add(1, 'days')) {
      if(!data.data.activity[moment(m).format('YYYY-MM-DD')]) {
        data.data.activity[moment(m).format('YYYY-MM-DD')] = 0;
      }
    }
    dispatch(actions.getAnalyticDemo.success(data));
    return Promise.resolve(data);
  } catch(e) {
    dispatch(actions.getAnalyticDemo.failure(parseError(e)));
    return Promise.reject(e);
  }
}