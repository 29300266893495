import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeExperienceActiveAsync } from '@ducks/roar';

export default function useMakeExperienceActive(cb) {
  const dispatch = useDispatch();

  return useCallback(
    async (experience, roar) => {
      try {
        await dispatch(
          makeExperienceActiveAsync({
            roarId: roar.roarId || roar.id,
            experienceId: experience.experienceId || experience.id
          })
        );

        if (cb) {
          cb(null, experience, roar);
        }
      } catch (e) {
        if (cb) {
          cb(e);
        }
      }
    },
    [cb, dispatch]
  );
}
