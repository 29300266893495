import React from 'react';

import {
  ARKIT_ARCORE_TYPE,
  FACEBOOK_TYPE,
  normalizeRoar,
  TARGET_IMAGE_TYPE,
  WEBAR_TYPE
} from '@ducks/roar';

import useModal from './useModal';

const TargetImagePreviewDialog = React.lazy(() =>
  import('../components/Roar/TargetImagePreviewDialog')
);
const ArKitArCorePreviewDialog = React.lazy(() =>
  import('../components/Roar/ArKitArCorePreviewDialog')
);
const WebArPreviewDialog = React.lazy(() =>
  import('../components/Roar/WebArPreviewDialog')
);
const FacebookPreviewDialog = React.lazy(() =>
  import('../components/Roar/FacebookPreviewDialog')
);

export default function usePreviewRoar() {
  const { showModal } = useModal();

  return React.useCallback(
    (roar = {}) => {
      const { sharingLink, markerImage, arPlatform } = normalizeRoar(roar);
      const dialogProps = { sharingLink, image: markerImage };

      switch (arPlatform) {
        case TARGET_IMAGE_TYPE:
          return showModal(TargetImagePreviewDialog, dialogProps);
        case ARKIT_ARCORE_TYPE:
          return showModal(ArKitArCorePreviewDialog, dialogProps);
        case WEBAR_TYPE:
          return showModal(WebArPreviewDialog, dialogProps);
        case FACEBOOK_TYPE:
          return showModal(FacebookPreviewDialog, dialogProps);
        default:
          return {};
      }
    },
    [showModal]
  );
}
