import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import some from 'lodash/some';

const isPendingAction = (state, action) => get(state.pending, action) === true;

export const selectPendingState = createSelector(
  (state, actions) =>
    Array.isArray(actions)
      ? some(actions, action => isPendingAction(state, action))
      : isPendingAction(state, actions),
  isPending => isPending
);

export const makePendingStateSelector = () => {
  return selectPendingState;
};
