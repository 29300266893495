import endsWith from 'lodash/endsWith';

import { getActionName } from '../../../utilities';

const pendingReducer = (state = {}, action) => {
  const { type } = action;
  const actionName = getActionName(type);

  if (!actionName) {
    return {
      ...state
    };
  }

  if (endsWith(type, '_REQUEST')) {
    return {
      ...state,
      [actionName]: true
    };
  }

  if (endsWith(type, '_SUCCESS') || endsWith(type, '_FAILURE')) {
    return {
      ...state,
      [actionName]: false
    };
  }

  return {
    ...state
  };
};

export default pendingReducer;
