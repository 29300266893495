import qs from 'querystring';

import replace from 'lodash/replace';

import noImage from '../../../assets/images/no-image.svg';
import { dynamicDeepLinkConfig } from '../../../utilities';

import {
  ARCHIVED_WORKFLOW_STATE_CODE,
  ARKIT_ARCORE_TYPE,
  DRAFT_WORKFLOW_STATE_CODE,
  FACEBOOK_TYPE,
  PUBLISHED_WORKFLOW_STATE_CODE,
  TARGET_IMAGE_TYPE,
  WEBAR_TYPE
} from './constants';

export function generateFullDynamicDeepLink(
  link,
  queryParams = dynamicDeepLinkConfig
) {
  const queryString = qs.stringify(queryParams);
  const deepLink = `https://theroar.page.link/?link=${encodeURIComponent(
    link
  )}&${queryString}`;

  return deepLink;
}

export function generateSharingLink(roarId, pageName, createdByName) {
  return `augmented-reality-${roarId}-${pageName}-by-${createdByName}`;
}

export function generateFullSharingLink(
  sharingLink,
  workflowStateCode,
  arPlatform,
  authorId
) {
  const isPublic = workflowStateCode === PUBLISHED_WORKFLOW_STATE_CODE;
  const url = `${process.env.REACT_APP_WEBSITE_HOST}/gallery-en/`;

  const queryStringParams = {
    item: sharingLink,
    type: arPlatform
  };

  if (!isPublic) {
    queryStringParams.isPublic = false;
  }

  if (authorId) {
    queryStringParams.ownerId = authorId;
  }

  const link = `${url}?${qs.stringify(queryStringParams)}`;

  if (arPlatform === ARKIT_ARCORE_TYPE || arPlatform === TARGET_IMAGE_TYPE) {
    return generateFullDynamicDeepLink(link, dynamicDeepLinkConfig);
  }

  return link;
}

export function getRoarSharingLink(roar = {}) {
  let sharingLink;

  if (roar.sharingLink) {
    sharingLink = roar.sharingLink;
  }

  sharingLink = generateSharingLink(roar.id, roar.pageName, roar.createdByName);

  return generateFullSharingLink(
    sharingLink,
    roar.workflowStateCode,
    roar.arPlatform,
    roar.createdById
  );
}

export function getRoarMarkerImage(roar = {}) {
  if (roar.markerImage) {
    return roar.arPlatform === TARGET_IMAGE_TYPE
      ? replace(roar.markerImage, 'icon', 'cropped')
      : roar.markerImage;
  }

  if (roar.s3BucketPath && roar.marker && roar.marker.croppedUrl) {
    return `${roar.s3BucketPath + roar.marker.croppedUrl}`;
  }

  return noImage;
}

/**
 * ROAR modal is created in a chaotic manner on the backend.
 * This function makes it normalized.
 */
export function normalizeRoar(roar = {}) {
  const {
    authorId,
    roarId,
    roarCode,
    name,
    sharingLink,
    markerImage,
    ...otherProps
  } = roar;

  const newRoar = { ...otherProps };

  if (authorId) {
    newRoar.createdById = authorId;
  }

  if (roarId) {
    newRoar.id = roarId;
  }

  if (roarCode) {
    newRoar.pageCode = roarCode;
  }

  if (name) {
    newRoar.pageName = name;
  }

  if (!markerImage) {
    let newMarkerImage = noImage;

    if (roar.s3BucketPath && roar.marker && roar.marker.croppedUrl) {
      newMarkerImage = `${roar.s3BucketPath + roar.marker.croppedUrl}`;
    }

    newRoar.markerImage = newMarkerImage;
  } else if (roar.arPlatform === TARGET_IMAGE_TYPE) {
    newRoar.markerImage = replace(markerImage, 'icon', 'cropped');
  } else {
    newRoar.markerImage = markerImage;
  }

  let newSharingLink = sharingLink;

  if (!newSharingLink) {
    newSharingLink = generateSharingLink(
      newRoar.id,
      newRoar.pageName,
      newRoar.createdByName
    );
  }

  if (newSharingLink) {
    newRoar.sharingLink = generateFullSharingLink(
      newSharingLink,
      newRoar.workflowStateCode,
      newRoar.arPlatform,
      newRoar.createdById
    );
  }

  return newRoar;
}

export default function canPreview(item) {
  const isDraft = item.workflowStateCode === DRAFT_WORKFLOW_STATE_CODE;
  const isArchived = item.workflowStateCode === ARCHIVED_WORKFLOW_STATE_CODE;
  const isFacebookPlatform = item.arPlatform === FACEBOOK_TYPE;
  const isWebArPlatform = item.arPlatform === WEBAR_TYPE;
  const disabledPreview =
    (isFacebookPlatform || isWebArPlatform) && (isDraft || isArchived);

  return !disabledPreview;
}
