import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsSignedIn } from '@ducks/user';
import { selectWsConnection, wsConnect } from '@ducks/websocket';
import { getJwtToken } from '@utilities';

export default function useWebsocket() {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(selectIsSignedIn);
  const isWsConnected = useSelector(selectWsConnection);

  useEffect(() => {
    if (!isWsConnected && isSignedIn) {
      dispatch(
        wsConnect({
          token: getJwtToken()
        })
      );
    }
  }, [dispatch, isSignedIn, isWsConnected]);
}
