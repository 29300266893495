import palette from '../palette';

export default {
  root: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1.5,
    color: palette.secondary.main
  }
};
