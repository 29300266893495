import colors from './colors';

export default {
  primary: {
    main: colors.primaryColor,
    dark: colors.darkerPrimaryColor,
    contrastText: colors.white
  },
  secondary: {
    main: colors.secondaryColor,
    contrastText: colors.white
  },
  error: {
    main: colors.errorColor
  },
  grey: {
    300: colors.grey300
  },

  text: {
    primary: colors.primaryText,
    secondary: colors.secondaryText
  },
  background: {
    default: colors.defaultBackground,
    paper: colors.paperBackground
  },
  action: {
    disabledBackground: colors.disabledBackground,
    hoverOpacity: '0.1'
  }
};
