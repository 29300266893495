import { createAction } from '@reduxjs/toolkit';

import { createThunkActions } from '../../../utilities';

export const setCurrentUser = createAction('@@user/SET_CURRENT_USER');
export const updateCurrentUser = createThunkActions(
  '@@user/UPDATE_CURRENT_USER'
);
export const deleteCurrentUser = createThunkActions(
  '@@user/DELETE_CURRENT_USER'
);
export const signIn = createThunkActions('@@user/SIGN_IN');
export const signInPrice = createThunkActions('@@user/SIGN_IN_PRICE');
export const signOut = createThunkActions('@@user/SIGN_OUT');
export const signUp = createThunkActions('@@user/SIGN_UP');
export const facebookAuth = createThunkActions('@@user/FACEBOOK_AUTH');
export const confirmEmail = createThunkActions('@@user/CONFIRM_EMAIL');
export const resendConfirmationEmail = createThunkActions(
  '@@user/RESEND_CONFIRMATION_EMAIL'
);
export const getCurrentUser = createThunkActions('@@user/GET_CURRENT_USER');
export const recoveryPasswordRequest = createThunkActions(
  '@@user/RECOVERY_PASSWORD_REQ'
);
export const recoveryPasswordConfirm = createThunkActions(
  '@@user/RECOVERY_PASSWORD_CONFIRM'
);
export const updateCurrentUserPassword = createThunkActions(
  '@@user/UPDATE_CURRENT_USER_PASSWORD'
);
export const setReceiveReceipts = createThunkActions(
  '@@user/SET_RECEIVE_RECEIPTS'
);
export const declineEmail = createThunkActions('@@user/DECLINE_EMAIL');
