import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';

const baseURL = 'payment';
const paymentTokenEndpoint = new Endpoint(`${baseURL}/token`);
const paymentMethodEndpoint = new Endpoint(`${baseURL}/paymentMethod`);
const paymentMethodInfoEndpoint = new Endpoint(`${baseURL}/paymentMethodInfo`);
const paymentMethodCheckoutEndpoint = new Endpoint(`${baseURL}/checkout`);

export const getPaymentToken = async () => {
  try {
    const response = await paymentTokenEndpoint.get();

    if (response.data && response.data.success && response.data.token) {
      return response.data.token;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPaymentMethodInfo = async () => {
  try {
    const response = await paymentMethodInfoEndpoint.get();

    /**
     * @todo check success === true
     */
    if (response.data) {
      return response.data.paymentMethodInfoDTO || {};
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPaymentCheckout = async payload =>  {
  try {
    const response = await paymentMethodCheckoutEndpoint.post(payload);
    
    if(response.data && response.data.success) {
      return response.data
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const setPaymentMethod = async payload => {
  try {
    const response = await paymentMethodEndpoint.put(payload);

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deletePaymentMethod = async () => {
  try {
    const response = await paymentMethodEndpoint.delete();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};
