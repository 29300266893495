import { createReducer } from '@reduxjs/toolkit';

import {
  wsConnected,
  wsDisconnected,
  wsReceiveMessage,
  wsResetMessage
} from './actions';

const initialState = {
  connected: false,
  message: {}
};

const webSocketReducer = createReducer(initialState, {
  [wsConnected]: state => {
    state.connected = true;
  },
  [wsDisconnected]: state => {
    state.connected = false;
    state.message = {};
  },
  [wsReceiveMessage]: (state, action) => {
    state.message = action.payload;
  },
  [wsResetMessage]: state => {
    state.message = {};
  }
});

export default webSocketReducer;
