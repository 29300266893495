import { createSelector } from '@reduxjs/toolkit';
import filter from 'lodash/filter';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';

import {
  CUSTOM_PLAN_IDENTIFIER,
  FREE_PLAN_TYPE,
  SUBSCRIPTION_PLAN_TYPE,
  VIEW_ONLY_PLAN_IDENTIFIER
} from './constants';
import { getFuturePlanTargetId } from './utilities';

export const selectPlans = state => state.plan.plans;

/**
 * custom plans do not work, so sometimes we filter them in the selector
 */
export const selectAvilablePlans = createSelector(selectPlans, plans =>
  filter(
    plans,
    plan =>
      plan.identifier !== CUSTOM_PLAN_IDENTIFIER && plan.identifier !== VIEW_ONLY_PLAN_IDENTIFIER &&
      (plan.type === SUBSCRIPTION_PLAN_TYPE || plan.type === FREE_PLAN_TYPE)
  )
);

export const selectEducatorPlan = createSelector(selectPlans, plans =>
  filter(plans, plan => plan.identifier === 'EDUCATOR')
);

export const selectPlansByFrequency = createSelector(
  selectPlans,
  (_, billingFrequency) => billingFrequency,
  (plans, billingFrequency) =>
    filter(plans, plan => plan.billingFrequency === billingFrequency)
);

export const makeSelectPlansByFrequency = () => selectPlansByFrequency;

export const selectPlanBy = createSelector(
  selectPlans,
  (_, predicate) => predicate,
  (plans, predicate) => find(plans, predicate) || {}
);

export const makeSelectPlanBy = () => selectPlanBy;

export const selectPlansGroupedBy = createSelector(
  selectPlans,
  (_, iteratee) => iteratee,
  (plans, iteratee) => groupBy(plans, iteratee)
);

export const makeSelectPlansGroupedBy = () => selectPlansGroupedBy;

export const selectAvilablePlansGroupedBy = createSelector(
  selectAvilablePlans,
  (_, iteratee) => iteratee,
  (plans, iteratee) => groupBy(plans, iteratee)
);

export const makeSelectAvilablePlansGroupedBy = () =>
  selectAvilablePlansGroupedBy;

export const selectPlansForPricing = createSelector(
  selectAvilablePlans,
  plans =>
    filter(
      plans,
      plan =>
        (!plan.billingFrequency || plan.billingFrequency === 1) &&
        plan.identifier !== CUSTOM_PLAN_IDENTIFIER
    )
);

export const selectCloseProp = state => state.plan.closeProp;

export const selectActivePlan = state => state.plan.activePlan;
export const selectFuturePlan = state => state.plan.futurePlan;
export const selectPreviousPlan = state => state.plan.previousPlan;
export const selectCoupon = state => state.plan.coupon;

export const selectActivePlanDetail = createSelector(
  [selectPlans, selectActivePlan],
  (plans, activePlan) => 
    find(plans, { id: activePlan.planId }) || {}
)

export const selectFuturePlanByTargetId = createSelector(
  [selectFuturePlan, selectPlans],
  (futurePlan, plans) =>
    find(plans, { id: getFuturePlanTargetId(futurePlan) }) || {}
);

export const makeSelectFuturePlanByTargetId = () => {
  return selectFuturePlanByTargetId;
};

export const selectCurrentPlanWithDetail = createSelector(
  [selectPlans, selectActivePlan],
  (plans, activePlan) => 
    filter(
      plans,
      plan => (plan.id === activePlan.planId)
    )
)