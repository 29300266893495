import palette from '../palette';

export default {
  root: {
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 1.357,
    color: palette.secondary.main
  }
};
