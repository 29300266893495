/* eslint-disable max-classes-per-file */
import Link from '@material-ui/core/Link';
import { createAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import isNumber from 'lodash/isNumber';
import slice from 'lodash/slice';

import * as localStorage from '../services/local-storage-service';
import RouterLink from '../views/components/RouterLink';

export class CodeError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

export class ApiError extends Error {
  constructor(message, code, timestamp, path, status, type) {
    super(message);

    this.code = code;
    this.timestamp = timestamp;
    this.path = path;
    this.status = status;
    this.type = type;
    this.path = path;
    this.isBadStatus = isNumber(status); // specify bad status codes
  }
}

export const parseError = ({
  message,
  code,
  timestamp,
  path,
  status,
  type,
  isBadStatus
}) => ({
  message,
  code,
  timestamp,
  path,
  status,
  type,
  isBadStatus
});

export const parseErrorResponseDataMessage = response => {
  return Promise.reject(new Error(response.data.message || 'Failure'));
};

export const createThunkActions = actionName => {
  if (typeof actionName !== 'string') {
    throw new Error('actionName must be a string');
  }

  return {
    actionName,
    request: createAction(`${actionName}_REQUEST`),
    success: createAction(`${actionName}_SUCCESS`),
    failure: createAction(`${actionName}_FAILURE`)
  };
};

export const getActionName = actionType => {
  if (typeof actionType !== 'string') {
    return null;
  }

  return actionType
    .split('_')
    .slice(0, -1)
    .join('_');
};

export function paginate(array, limit, page) {
  return slice(array, page * limit, (page + 1) * limit);
}

export function getNavigationItemComponentProps(item = {}) {
  const { to, href, private: isPrivate, ...otherProps } = item;
  const props = { ...otherProps };

  if (item.to) {
    props.component = RouterLink;
    props.to = to;
  }

  if (item.href) {
    props.component = Link;
    props.color = 'inherit';
    props.href = href;
  }

  return props;
}

export function getAutocompletePacContainer(autocomplete) {
  const defaultReturn = { remove: () => {} };

  if (!autocomplete) {
    return defaultReturn;
  }

  // eslint-disable-next-line no-underscore-dangle
  const { place } = autocomplete.gm_accessors_;

  if (!place) {
    return defaultReturn;
  }

  const placeKey = Object.keys(place).find(
    value =>
      typeof place[value] === 'object' &&
      // eslint-disable-next-line no-prototype-builtins
      place[value].hasOwnProperty('gm_accessors_')
  );

  // eslint-disable-next-line no-underscore-dangle
  const input = place[placeKey].gm_accessors_.input[placeKey];

  const inputKey = Object.keys(input).find(
    value =>
      input[value].classList && input[value].classList.contains('pac-container')
  );

  return input[inputKey];
}

const getDomain = () =>
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_DOMAIN || '.theroar.io'
    : undefined;

export function getNextWeek() {
  const today = new Date();
  const nextWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  );

  return nextWeek;
}

/**
 * @todo remove localStorage
 */
export function getJwtToken() {
  return localStorage.getItem('token') || Cookies.get('token');
}

/**
 * @todo remove localStorage
 */
export function setJwtToken(token) {
  Cookies.set('token', token, {
    domain: getDomain(),
    secure: process.env.NODE_ENV === 'production',
    expires: getNextWeek()
  });

  localStorage.setItem('token', token);
}

/**
 * @todo remove localStorage
 */
export function removeJwtToken() {
  Cookies.remove('token', { domain: getDomain() });
  localStorage.removeItem('token');
}

export function setGuestUser({username, planId}) {
  localStorage.setItem('guestUser', {username, planId})
} 

export function getGuestUser() {
  return localStorage.getItem('guestUser');
}
