import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDeepCompareCallback } from 'use-deep-compare';

import { filterAndSearchForOwnerAsync } from '@ducks/roar';

export default function useFilterAndSearchRoarForOwner(initialParams = {}, cb) {
  const dispatch = useDispatch();
  const callback = useCallback(cb, []);

  return useDeepCompareCallback(
    (params = {}) => {
      dispatch(filterAndSearchForOwnerAsync({ ...initialParams, ...params }))
        .then(data => {
          if (callback) {
            callback(undefined, data);
          }
        })
        .catch(error => {
          if (callback) {
            callback(error);
          }
        });
    },
    [dispatch, initialParams, callback]
  );
}
