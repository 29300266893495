import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    outline: 'none',
    border: 'none',
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.standard
    }),
    color: 'inherit',

    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent'
    },

    '& .MuiButton-endIcon': {
      marginLeft: 0
    }
  }
}));

const TopbarLinkButton = React.forwardRef(function LinkButton(
  { className, children, ...props },
  ref
) {
  const classes = useStyles();

  return (
    <Button ref={ref} className={clsx(classes.root, className)} {...props}>
      {children}
    </Button>
  );
});

TopbarLinkButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

TopbarLinkButton.defaultProps = {
  className: ''
};

export default TopbarLinkButton;
