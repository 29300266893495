// import { fade } from '@material-ui/core/styles/colorManipulator';

// import colors from '../colors';
// import palette from '../palette';

export default {
  // root: {
  //   fontSize: 12,
  //   fontWeight: 500,
  //   lineHeight: 1.333,
  //   '&.Mui-selected': {
  //     backgroundColor: 'transparent'
  //   },
  //   '&.Mui-selected:hover': {
  //     backgroundColor: 'transparent'
  //   }
  // },
  // button: {
  //   borderTop: '1px solid transparent',
  //   borderBottom: '1px solid transparent',
  //   '&:hover': {
  //     backgroundColor: fade(palette.primary.main, 0.16),
  //     borderTopColor: colors.borderColor,
  //     borderBottomColor: colors.borderColor
  //   }
  // }
};
