import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import roarLogoFooter from '../../assets/images/roar_logo_footer.svg';
import FooterLogoIcon from './FooterLogoIcon';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: 60,
    padding: theme.spacing(2, 8),
    fontFamily: '"Roboto", sans-serif',
    color: theme.colors.footerColor,
    backgroundColor: theme.colors.footerBackgroundColor,
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 8)
    },

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 25)
    }
  },
  container: {
    padding: 20,
    width: '100%'
  },
  link: {
    color: 'inherit',

    '&:hover': {
      textDecoration: 'none'
    }
  },
  text: {
    fontSize: 13,
    lineHeight: '13px'
  },
  logo: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  logoIcon: {
    minWidth: 75,
    minHeight: 20,
    width: 75,
    height: 24,
    verticalAlign: 'middle'
  }
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={6} alignItems="center" justify="space-between">
          <Grid item>
            <Grid container spacing={6} alignItems="center">
              <Grid item xs={12} sm="auto">
                <IconButton
                  className={classes.logo}
                  href={process.env.REACT_APP_WEBSITE_HOST}
                  component={Link}
                  color="inherit"
                >
                  <img src={roarLogoFooter} className={classes.logoIcon} />
                </IconButton>
              </Grid>
              <Grid item>
                <Link
                  className={clsx(classes.link, classes.text)}
                  component={Link}
                  href={`${process.env.REACT_APP_WEBSITE_HOST}/privacy/`}
                >
                  {t('translation:privacy-and-cookies')}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={clsx(classes.link, classes.text)}
                  component={Link}
                  href={`${process.env.REACT_APP_WEBSITE_HOST}/terms/`}
                >
                  {t('translation:terms-and-conditions')}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={clsx(classes.link, classes.text)}
                  component={Link}
                  href={`${process.env.REACT_APP_WEBSITE_HOST}/support/`}
                >
                  Support
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={clsx(classes.link, classes.text)}
                  component={Link}
                  href={`${process.env.REACT_APP_WEBSITE_HOST}/contact-us/`}
                >
                  {t('translation:contact-us')}
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography className={classes.text}>
              {t('translation:copyright-text')} &copy; 2020
            </Typography>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};

export default Footer;
