import { fade } from '@material-ui/core/styles/colorManipulator';

import colors from '../colors';
import palette from '../palette';
import typography from '../typography';

export default theme => ({
  root: {
    ...typography.body1,
    padding: theme.spacing(2, 2.5),
    borderBottom: `1px solid ${fade(colors.borderColor, 0.7)}`
  },
  head: {
    ...typography.body1,
    fontWeight: 700,
    padding: theme.spacing(4, 2.5),
    '&>div': {
      color: colors.headText
    }
  },
  body: {
    color: palette.secondary.main
  }
});
