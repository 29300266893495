import pick from 'lodash/pick';

import { parseErrorResponseDataMessage } from '@utilities';
import { Endpoint } from '@utilities/api-client';
import { ANALYTICS } from '@/state/ducks/addon';

const baseEndpoint = new Endpoint('plan');
const activePlanEndpoint = new Endpoint(`activePlan/me`);
const activePlanCouponEndpoint = new Endpoint('activePlan/me/coupon');
const couponEndpoint = new Endpoint(
  ({ couponName = '' }) => `/activePlan/coupon/${couponName}`
);
const unsubscribePlanEndpoint = new Endpoint('activePlan/me/unsubscribe');
const retryPaymentEndpoint = new Endpoint('activePlan/retry');

export const getPlan = async () => {
  try {
    const response = await baseEndpoint.get();

    if (response.data && response.data.success && response.data.plans) {
      return response.data.plans;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getActivePlan = async () => {
  try {
    const response = await activePlanEndpoint.get();

    if (response.data && response.data.success) {
      return pick(response.data, ['activePlan', 'scheduledDowngradeTo']);
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const setActivePlan = async (plan, coupon) => {
  try {
    const response = await activePlanEndpoint.put(undefined, {
      params: {
        planId: plan.id,
        coupon
      }
    });

    if (response.data && response.data.success) {
      return pick(response.data, ['activePlan', 'scheduledDowngradeTo', 'purchaseInfo']);
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const retryPayment = async () => {
  try {
    const response = await retryPaymentEndpoint.get();

    if(response.data && response.data.success) {
      return parseErrorResponseDataMessage({
        data: {
          message: 'Your renewal payment has not processed. We will attempt to automatically retry in 24 hours.'
        }
      });
    } else {
      return response.data;
    }

  } catch(e) {
    return Promise.reject(e);
  }
}

export const setActivePlanAddOn = async (plan, coupon, addonName) => {
  try {
    const response = await activePlanEndpoint.put(undefined, {
      params: {
        planId: plan.id,
        coupon,
        addon: addonName
      }
    });

    if(response.data && response.data.success) {
      return pick(response.data, ['activePlan', 'scheduleDowngradeTo']);
    }

    return parseErrorResponseDataMessage(response);
  } catch(e) {
    return Promise.reject(e);
  }
}

export const getActivePlanCoupon = async () => {
  try {
    const response = await activePlanCouponEndpoint.get();

    if (response.data && response.data.success) {
      return response.data.discount || {};
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getCouponInfo = async couponName => {
  try {
    const response = await couponEndpoint.get({
      uriParams: { couponName }
    });

    if (response.data && response.data.success) {
      return response.data.discount || {};
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const unsubscribePlan = async () => {
  try {
    const response = await unsubscribePlanEndpoint.put();

    if (response.data && response.data.success) {
      return response.data;
    }

    return parseErrorResponseDataMessage(response);
  } catch (e) {
    return Promise.reject(e);
  }
};
