import React from 'react';
import PropTypes from 'prop-types';

import useMarkerScanConfirmation from '@views/hooks/useMarkerScanConfirmation';

export default function withScanConfirmation(Component) {
  function WithScanConfirmation({
    onConfirm,
    scanConfirmationOptions = {},
    ...props
  }) {
    const confirmed = useMarkerScanConfirmation(
      onConfirm,
      scanConfirmationOptions
    );

    return <Component isPending={!confirmed} {...props} />;
  }

  WithScanConfirmation.propTypes = {
    scanConfirmationOptions: PropTypes.shape({}),
    onConfirm: PropTypes.func.isRequired
  };

  return WithScanConfirmation;
}
