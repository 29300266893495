// eslint-disable-next-line import/prefer-default-export
export const logErrorAsync = (body, source) => async (
  dispatch,
  getStore,
  { api }
) => {
  try {
    await api.logger.logError(body, source);
  } catch (error) {
    console.error(error);
  }
};
