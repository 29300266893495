import { createThunkActions } from '../../../utilities';

export const filterAndSearchForOwner = createThunkActions(
  '@@roar/FILTER_AND_SEARCH_FOR_OWNER'
);
export const filterAndSearchExperienceForOwner = createThunkActions(
  '@@roar/FILTER_AND_SEARCH_EXPERIENCE_FOR_OWNER'
);
export const getByPageCode = createThunkActions('@@roar/GET_BY_PAGE_CODE');
export const getById = createThunkActions('@@roar/GET_BY_ID');
export const getAnalyticsById = createThunkActions('@@roar/GET_ANALYTICS_BY_ID');
export const changeStatusByPageCode = createThunkActions(
  '@@roar/CHANGE_STATUS_BY_PAGE_CODE'
);
export const deleteByPageCode = createThunkActions(
  '@@roar/DELETE_BY_PAGE_CODE'
);
export const deleteExperience = createThunkActions('@@roar/DELETE_EXPERIENCE');
export const makeExperienceActive = createThunkActions(
  '@@roar/MAKE_EXPERIENCE_ACTIVE'
);
export const publishRoar = createThunkActions('@@roar/PUBLISH_ROAR');
export const getRoarLinkOpeningCount = createThunkActions(
  '@@roar/GET_ROAR_LINK_OPENING_COUNT'
);
export const getRoarScanCount = createThunkActions(
  '@@roar/GET_ROAR_SCAN_COUNT'
);
export const checkRoarBeforePublish = createThunkActions(
  '@@roar/CHECK_ROAR_BEFORE_PUBLISH'
);
export const getRoarForPreview = createThunkActions(
  '@@roar/GET_ROAR_FOR_PREVIEW'
);

export const getDemos = createThunkActions('@@demo/GET_DEMOS');
export const getDemo = createThunkActions('@@demo/GET_DEMO');
export const saveDemo = createThunkActions('@@demo/SAVE_DEMO');
export const updateDemo = createThunkActions('@@democ/UPDATE_DEMO');
export const deleteDemo = createThunkActions('@@demo/DELETE_DEMO');
export const uploadFile = createThunkActions('@@demo/UPLOAD_FILE');
export const getAnalyticDemo = createThunkActions('@@demo/GET_ANALYTICS_DEMO');

export const getWebARs = createThunkActions('@@webar/GET_WEBARS');
export const getWebAR = createThunkActions('@@webar/GET_WEBAR');
export const saveWebAR = createThunkActions('@@webar/SAVE_WEBAR');
export const updateWebAR = createThunkActions('@@webar/UPDATE_WEBAR');
export const deleteWebAR = createThunkActions('@@webar/DELETE_WEBAR');
export const uploadWebARFile = createThunkActions('@@webar/UPLOAD_WEBAR_FILE');