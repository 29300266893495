import { createReducer } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

import {
  deleteCurrentUser,
  getCurrentUser,
  setCurrentUser,
  setReceiveReceipts,
  signIn,
  signInPrice,
  signOut,
  signUp,
  updateCurrentUser
} from './actions';

const initialState = {};

export default createReducer(initialState, {
  [signIn.success]: (state, action) => action.payload,
  [signInPrice.success]: (state, action) => action.payload,
  [signUp.success]: (state, action) => action.payload,
  [signOut.success]: () => initialState,
  [getCurrentUser.success]: (state, action) => action.payload,
  [setCurrentUser]: (state, action) => action.payload,
  [updateCurrentUser.success]: (state, action) => {
    state = assign(state, action.payload);
  },
  [setReceiveReceipts.success]: (state, action) => {
    state.receivePaymentReceipts = action.payload;
  },
  [deleteCurrentUser.success]: () => initialState
});
