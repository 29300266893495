import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import GoogleTagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import store from '@state/store';
import App from '@views/routes';

import history from './history';
import * as serviceWorker from './serviceWorker';

ReactGA.initialize(process.env.REACT_APP_GA_IDENTIFIER);

GoogleTagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayer: {
    js: new Date(),
    config: process.env.REACT_APP_GA_IDENTIFIER
  }
});

history.listen(({ pathname, search }) => {
  const page = `${pathname}${search}`;
  ReactGA.pageview(page);
  ReactGA.set({ page });
});

require('./i18n');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
