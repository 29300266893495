import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import Loader from './Loader';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1
  },
  text: {
    marginTop: theme.spacing(4),
    textShadow: '16px 12px 8px rgba(0, 0, 0, 0.04)',
    fontSize: 24,
    color: '#0B2027'
  }
}));

export default function PageLoader(props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.className)}>
      <Loader
        disableGutters
        disableBackground={false}
        fullWidth={false}
        size={32}
        thickness={6}
        {...props}
      />
      <Typography className={classes.text} variant="h3">
        {props.title?props.title:'Loading'}
      </Typography>
    </div>
  );
}
