import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import VerifyEmailDialog from '@components/VerifyEmailDialog';
import { selectCurrentUser } from '@ducks/user';

import useModal from './useModal';

export default function useEmailVerify() {
  const { showModal } = useModal();
  const user = useSelector(selectCurrentUser, shallowEqual);

  useEffect(() => {
    if (user.confirmed === false) {
      showModal(VerifyEmailDialog);
    }
  }, [showModal, user.confirmed]);
}
