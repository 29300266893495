import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers } from 'redux';

import * as reducers from '@ducks';
import { deleteCurrentUser, signOut } from '@ducks/user';

import history from '../history';
import services from '../services';

import authMiddleware from './auth-middleware';
import errorLoggerMiddleware from './error-logger-middleware';
import createWebSocketMiddleware from './websocket-middleware';

const rootReducer = (state, action) => {
  if (
    signOut.success.match(action) ||
    deleteCurrentUser.success.match(action)
  ) {
    state = undefined;
  }

  return combineReducers({
    router: connectRouter(history),
    ...reducers
  })(state, action);
};

const socketMiddleware = createWebSocketMiddleware(
  process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_API_URL}/websocket/`
    : '/api/websocket/'
);

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    routerMiddleware(history),
    ...getDefaultMiddleware({
      thunk: {
        extraArgument: services
      }
    }),
    authMiddleware,
    socketMiddleware,
    errorLoggerMiddleware
  ]
});

export default store;
