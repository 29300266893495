import { createReducer } from '@reduxjs/toolkit';

import {
  getActiveAnalyticsAddon,
  getAnalyticsPriceAddon,
  subscribeAnalyticsAddon,
  revokeAnalyticsAddon,
  getAllAnalyticsPriceAddon
} from './actions';

const initialState = {
  analytics: {
    price: 0,
    prices: [],
    plan: {},
    id: 'ANALYTICS',
    title: 'Advanced Analytics'
  }
};

export default createReducer(initialState, {
  [getActiveAnalyticsAddon.success]: (state, action) => {
    state.analytics.plan = action.payload;
  },
  [getAnalyticsPriceAddon.success]: (state, action) => {
    state.analytics.price = action.payload.price;
  },
  [subscribeAnalyticsAddon.success]: (state, action) => {
    state.analytics.plan = { success: true };
  },
  [revokeAnalyticsAddon.success]: (state, action) => {
    state.analytics.plan = { success: false };
  },
  [getAllAnalyticsPriceAddon.success]: (state, action) => {
    state.analytics.prices = action.payload.plans;
  }
});