export default {
  root: {
    padding: 0
  },
  left: {
    flexGrow: 0
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
};
