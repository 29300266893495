import { createMuiTheme } from '@material-ui/core';
import merge from 'lodash/merge';

import colors from './colors';
import createMixins from './mixins';
import createOverrides from './overrides';
import palette from './palette';
import props from './props';
import shadows from './shadows';
import shape from './shape';
import typography from './typography';

const theme = createMuiTheme({
  colors,
  palette,
  typography,
  shadows,
  shape,
  spacing: 4
});

theme.overrides = merge(theme.overrides, createOverrides(theme));
theme.mixins = merge(theme.mixins, createMixins(theme));
theme.props = merge(theme.props, props);

export default theme;
