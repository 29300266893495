import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { selectIsSignedIn } from '@ducks/user';
import { getPath } from '@views/router-paths';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isSignedIn = useSelector(selectIsSignedIn);

  return (
    <Route
      {...rest}
      render={props => {
        if (!isSignedIn) {
          return (
            <Redirect
              to={{
                pathname: getPath('signIn'),
                // eslint-disable-next-line react/prop-types
                state: { from: props.location }
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};

export default PrivateRoute;
