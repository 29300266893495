export const ERROR_CODE_TOKEN_IS_INVALID = 401;
export const ERROR_CODE_MISSED_USER_ROLE = 402;

export const PASSWORD_MIN_LENGTH = 8;

export const CREATED_AT = 'createdAt';
export const DESC = 'desc';
export const ASC = 'asc';

export const AWSLAB_LAST_REQUEST = 'AWSLAB_LAST_REQUEST';
export const AWSLAB_MAX_IDLE_DURATION = 1000 * 60 * 60 * 1; // 1hr
export const AWSLAB_CHECK_INTERVAL = 1000 * 60 * 5; // 5m

export const RECOGNITIONS_LOW_LEVEL = 50;

export const maxVideoSize = 500 * 1024 * 1024;

export const dynamicDeepLinkConfig = {
  apn: 'com.roar.scanner',
  amv: 1080410031,
  isi: 1045561660,
  imv: 240,
  ibi: 'com.dynamix.roarviewer',
  efr: 1
};
