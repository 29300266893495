export const pathsMap = {
  home: () => '/',
  dashboard: () => '/dashboard',
  plans: () => '/plans',
  buy: ([planId, billingPeriod]) => {
    return `/buy/${planId}/${billingPeriod}`;
  },
  addon: ([planId, billingPeriod]) => {
    return `/addon/${planId}/${billingPeriod}`
  },
  teams: () => '/teams',
  signIn: () => '/sign-in',
  signUp: () => '/sign-up',
  signOut: () => '/sign-out',
  profile: () => '/profile',
  scanner: () => '/scanner',
  scannerView: () => '/scanner/view',
  profileAccount: () => '/profile/account',
  profilePassword: () => '/profile/password',
  profileBilling: () => '/profile/billing',
  profileInvoice: () => '/profile/invoice',
  recoveryPassword: token => `/recovery/${token}`,
  forgotPassword: () => '/forgot-password',
  myRoars: workflowStateCode => `/my-roars/${workflowStateCode}`,
  viewRoar: roarId => `/my-roar/${roarId}`,
  myExperiences: workflowStateCode => `/my-experiences/${workflowStateCode}`,
  createRoar: () => '/create-roar',
  verify: token => `/verify/${token}`,
  decline: token => `/decline/${token}`,
  editCampaign: campaignId => `/campaign/edit/${campaignId}`,
  viewCampaign: campaignId => `/campaign/view/${campaignId}`,
  analyticCampaign: campaignId => `/campaign/analytic/${campaignId}`,
  buttonAnalyticCampaign: campaignId => `/campaign/button-analytic/${campaignId}`,
  campaign: () => `/campaign`,
  listCampaign: () => `/campaign/list`,
  addCampaign: () => `/campaign/add`,
  webar: () => `/webar`,
  listWebar: () => `/webar/list`,
  addWebar: () => `/webar/add`,
  editWebar: webarId => `/webar/edit/${webarId}`,
  viewWebar: webarId => `/webar/view/${webarId}`,
  analytics: roarId => `/analytics/${roarId}`
};

export const getPath = (route, ...params) => {
  const pathCb = (...args) => pathsMap[route](args);
  return pathCb(...params);
};
